/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import './StudioEditor.scss';
import '../assets/global.scss';
import { v4 as uuidv4 } from 'uuid';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import posthog from 'posthog-js';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { Style } from 'react-style-tag';
import { clearImages } from '../redux/slices/images';
import { clearVideos } from '../redux/slices/videos';
import { clearError } from '../redux/slices/error';
import {
  addAnimation,
  addLayer,
  getCreativeSet,
  getTemplate,
  setTemplateDCO,
  setTemplateDuration,
  updateLayerDCO,
  updateLayerSettings
} from '../redux/slices/template';
import {} from '../redux/slices/auth';
import AssetsMenu from '../components/assetsMenu/AssetsMenu';
import Formats from '../components/formats/Formats';
import Banner from '../components/EditorCanvas/Banner';
import TopMenuContainer from '../components/topMenu/TopMenuContainer';
import DCOMiniTable from '../components/DCO/DCOMiniTable';
import AnimationSelection from '../components/RightMenu/RightMenuAnimationSelection';
import RightMenu from '../containers/RightMenuContainer';
import RightMenuTemplateClicktag from '../containers/RightMenuTemplateClicktagContainer';
import RightMenuTemplateSettings from '../components/RightMenu/RightMenuTemplateSettings';
import RightMenuBannerClicktag from '../containers/RightMenuBannerClicktagContainer';
import MediumIconButton from '../components/common/MediumIconButton';
import { getPathById, useClickOutside } from '../assets/utils';
import { flat } from '../../helpers';
import Draggable from '../components/DragNDrop/Draggable';
import Dropzone from '../components/DragNDrop/Dropzone';
import useEventListener from '../../utils/hooks/useEventListener';
import HotkeyActions from '../../HotkeyActions';
import BreakTheWorld from '../components/BreakTheWorld';
import Timeline from '../components/timeline/Timeline';
import ToggleRevealSectionButton from '../components/common/ToggleRevealSectionButton';
import usePosthogCapture from '../../utils/hooks/usePosthogCapture';
import RightMenuFinalise from '../components/RightMenu/RightMenuFinalise';
import DeleteWarning from '../components/common/styled/DeleteWarning';
import { setDcoSelection } from '../redux/slices/dco';
import DynamicTypeCard from '../components/dynamic/DynamicTypeCard';
import PreviewDynamicProducts from '../components/dynamic/PreviewDynamicProducts';
import QuickExportTypeSelection from '../components/quickExport/QuickExportTypeSelection';
import StyledSectionWrap from '../components/common/styled/StyledSectionWrap';
import useAuthorization from '../../utils/hooks/useAuthorization';
import {
  ZuuviLogo,
  TextIcon,
  FormatsIcon,
  ImagesIcon,
  CloseIcon,
  RectangleIcon,
  CircleIcon,
  EyeIcon,
  AnimateIcon,
  EditIcon,
  DynamicIcon,
  TrashcanIcon,
  TemplateSettingsIcon,
  EffectsIcon,
  LogOutIcon,
  GoToStudioOrCreativeManagerIcon,
  FinaliseIcon,
  MainDcoIcon,
  MainDcoIconLocked,
  MainDpaIcon,
  MainDpaIconLocked,
  ClicktagIcon,
  HiddenEyeIcon,
  ZuuviLogoSantaHat
} from '../assets/icons';
import { getDefaultLayerObject } from '../objectsTypes';
import '@zuuvi/zuuvi-nestable/dist/index.css';
import removeDcoTagFromTextById from '../../utils/removeDcoTagFromTextById';
import {
  buildAnimations,
  rerenderSlate,
  rerenderSlateEditor,
  resetActiveAnimation,
  setActiveAnimation,
  setEditorType,
  setIframeIsOpened,
  setLeftMenu,
  setRightMenu,
  stopAnimations
} from '../redux/slices/editorSession';
import {
  setQuickExportBigMenuOpened,
  resetSelectedQuickExportType
} from '../redux/slices/quickExport';
import useBeforeunload from '../../utils/hooks/useBeforeunload';
import OverviewMenu from '../components/DCO/OverviewMenu';
import CreateMenu from '../components/DCO/CreateMenu';
import ConfigureMenu from '../components/DCO/ConfigureMenu';
import theme from '../assets/theme';
import EditDatasourceModal from '../components/common/EditDatasourceModal';
import GoogleFontLoader from 'react-google-font-loader';
import Ruler from '../components/EditorCanvas/Ruler';
import CustomGuide from '../components/EditorCanvas/CustomGuide';

const StyledDynamicFeedOverviewTitle = styled.p`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
`;

function StudioEditor({ actualEditorType }) {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  gsap.registerPlugin(CustomEase);
  const errorState = useSelector((state) => state.error);
  const currentAccountId = useSelector((state) => state.auth.currentAccountId);

  const userSettings = useSelector((state) => state.userSettings);
  const template = useSelector((state) => state.template);
  const { scale, presentLayers, animation, selectedFonts, iframeIsOpened } = useSelector(
    (state) => state.editorSession.studio
  );
  const { selectedFormatId, selectedFormat, dirtySaveState, editorType } = useSelector(
    (state) => state.editorSession
  );

  // chrome popup is behind feature flag because of test user
  if (isAuthorized({ featureFlag: 'chrome-popup' })) {
    useBeforeunload(() => dirtySaveState && 'stopunload');
  }
  const { dcoSelection, editDatasourceModalOpened } = useSelector((state) => state.dco);

  const { quickExportBigMenuOpened, selectedQuickExportType } = useSelector(
    (state) => state.quickExport
  );

  const isModalOpenened = editDatasourceModalOpened || quickExportBigMenuOpened;

  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [currentTemplateId, setCurrentTemplateId] = useState(null);

  // Load data from template store if in Studio workspace
  const { templateId, creativeSetId } = useParams();

  const capturePosthogData = usePosthogCapture();

  const [userFonts, setUserFonts] = useState([]);
  const [googleFonts, setGoogleFonts] = useState([]);
  const [rulerPosition, setRulerPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    dispatch(setEditorType(actualEditorType));
    if (actualEditorType === 'banner' && creativeSetId) {
      dispatch(getCreativeSet(creativeSetId));
    } else {
      dispatch(getTemplate(templateId));
      setCurrentTemplateId(templateId);
    }
  }, []);

  // fetch customfonts and googlefonts
  useEffect(() => {
    if (currentAccountId && currentTemplateId) {
      dispatch(clearImages());
      dispatch(clearVideos());
    }
  }, [currentAccountId, currentTemplateId]);

  useEffect(() => {
    if (currentAccountId) {
      getFonts(currentAccountId);
    }
  }, [currentAccountId]);

  useEffect(() => {
    if (errorState && errorState.status) {
      if (errorState.status === 401) {
        keycloak.login();
      } else if (errorState.status === 404) {
        navigate('/');
      }
      dispatch(clearError());
    }
  }, [errorState]);

  useEffect(() => {
    if (editorType !== 'banner') {
      if (
        selectedFormat &&
        selectedFormat.object_data &&
        selectedFormat.object_data.width &&
        selectedFormat.object_data.height
      ) {
        navigate(
          `/editor/template/${template.id}/format/width/${selectedFormat.object_data.width}/height/${selectedFormat.object_data.height}`
        );
      }
    }
  }, [selectedFormat]);

  useEffect(() => {
    dispatch(
      setTemplateDCO({
        dynamic_uuid: dcoSelection.dynamicSourceIncluded ? dcoSelection.dynamicSource.id : '',
        parameter_uuid: dcoSelection.parameterSourceIncluded ? dcoSelection.parameterSource.id : '',
        custom: dcoSelection.customSourceIncluded ? dcoSelection.customSource : {},
        client_id: currentAccountId,
        number_of_objects: dcoSelection.dynamicSourceIncluded
          ? dcoSelection.dynamicSource.number_of_objects
          : 1
      })
    );
  }, [dcoSelection]);

  function getFonts(clientId) {
    fetch(window._env_.REACT_ENV_GET_GOOGLE_FONTS_URL)
      .then((gFontsRes) => gFontsRes.json())
      .then((googleFonts) => {
        setGoogleFonts(googleFonts.items);
      })
      .catch((e) => {
        console.log(e);
      });
    fetch(window._env_.REACT_ENV_GET_ALL_CUSTOM_FONTS_URL + clientId)
      .then((uFontsRes) => uFontsRes.json())
      .then((userFonts) => {
        setUserFonts(userFonts.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getFontsData(fontType) {
    if (fontType === 'google') {
      return selectedFonts
        .filter((fonts) => fonts.reference_type === fontType)
        .map((font) => ({ font: font.name, weights: font.weights }));
    }
    if (fontType === 'external') {
      return selectedFonts
        .filter((fonts) => fonts.reference_type === fontType)
        .map((font) => ({ name: font.name, source: font.source }));
    }
    return [];
  }

  const [copiedData, setCopiedData] = useState(null);

  const leftMenuTabRef = useRef();

  const leftNavRef = useRef();

  // ****** LEFT MENU ****** //
  const changeDcoSourceNameRef = useRef();
  const quickExportRef = useRef();

  const [openedAnimations, setOpenedAnimations] = useState([]);

  const [openedEffects, setOpenedEffects] = useState([]);

  const [isEditingTextEnabled, setIsEditingTextEnabled] = useState(null);
  const [isReadonlyTextEnabled, setIsReadonlyTextEnabled] = useState(null);

  const onIsTextEditingEnabled = (status) => {
    setIsEditingTextEnabled(status);
  };

  const rightMenuTabRef = useRef();

  // RIGHT TABS
  const [canvasStyle, setCanvasStyle] = useState({
    cursor: 'default',
    transform: 'translate(0px, 0px)'
  });
  const [canvasPosition, setCanvasPosition] = useState({ x: 0, y: 0 });
  const [canvasStartPosition, setCanvasStartPosition] = useState({ x: 0, y: 0 });
  const [canvasStartDrag, setCanvasStartDrag] = useState({ x: 0, y: 0 });
  const [canvasIsMovable, setCanvasIsMovable] = useState({
    mouse: false,
    space: false
  });

  const keydownHandler = useCallback((event) => {
    if (!isModalOpenened) {
      if (!canvasIsMovable.space && event.code === 'Space') {
        setCanvasIsMovable({
          ...canvasIsMovable,
          space: true
        });
      }
    }
  });

  const keyupHandler = useCallback((event) => {
    if (!isModalOpenened) {
      if (event.code === 'Space') {
        setCanvasIsMovable({
          ...canvasIsMovable,
          space: false
        });
      }
    }
  });

  const handelUpdateRulerPosition = () => {
    const banner = document.getElementById('banner');
    if (banner && banner.getBoundingClientRect()) {
      const clientRect = banner.getBoundingClientRect();
      if (clientRect.left !== rulerPosition.x || clientRect.top !== rulerPosition.y) {
        setRulerPosition(getRulerPosition());
      }
    }
  };

  useEffect(() => {
    // used for updating ruler on canvas move
    handelUpdateRulerPosition();
  });

  useEffect(() => {
    // used for updating ruler on window resizing
    window.addEventListener('resize', handelUpdateRulerPosition);
    return () => {
      window.removeEventListener('resize', handelUpdateRulerPosition);
    };
  }, []);

  useEventListener('keydown', keydownHandler);
  useEventListener('keyup', keyupHandler);

  function mouseupCanvasHandler(event) {
    event.preventDefault();
    setCanvasIsMovable({
      ...canvasIsMovable,
      mouse: false
    });
  }

  function mousemoveCanvasHandler(event) {
    if (canvasIsMovable.space && canvasIsMovable.mouse) {
      setCanvasPosition({
        x: canvasStartPosition.x + (event.pageX - canvasStartDrag.x),
        y: canvasStartPosition.y + (event.pageY - canvasStartDrag.y)
      });
      setCanvasStyle({
        ...canvasStyle,
        transform: `translate(${canvasPosition.x}px, ${canvasPosition.y}px)`
      });
    }
  }
  function mousedownCanvasHandler(event) {
    event.preventDefault();
    setCanvasStartDrag({
      x: event.pageX,
      y: event.pageY
    });
    setCanvasStartPosition({
      x: canvasPosition.x,
      y: canvasPosition.y
    });
    setCanvasIsMovable({
      ...canvasIsMovable,
      mouse: true
    });
  }

  let canvasCursor = 'default';
  if (canvasIsMovable.space && !canvasIsMovable.mouse) canvasCursor = 'grab';
  if (canvasIsMovable.space && canvasIsMovable.mouse) canvasCursor = 'grabbing';

  const canvasRealStyle = {
    ...canvasStyle,
    cursor: canvasCursor,
    minWidth: `${selectedFormat?.object_data?.width ?? 0}px`
  };

  const topHeaderRef = useRef();
  const timelineContainerRef = useRef();

  const handleDropzoneDrop = (e, object) => {
    // Change to use the banner ref to get it
    const banner = document.getElementById('banner').getBoundingClientRect();

    object.format.x = e.pageX - banner.left - ((e.pageX - banner.left) / scale) * (scale - 100);
    object.format.y = e.pageY - banner.top - ((e.pageY - banner.top) / scale) * (scale - 100);
    dispatch(addLayer(object, selectedFormatId));
  };

  // SHORTCUTS
  HotkeyActions({
    copiedData,
    setCopiedData
  });

  const toolbarRef = useRef();

  const getRulerPosition = () => {
    const banner = document.getElementById('banner');

    if (banner && banner.getBoundingClientRect()) {
      const clientRect = banner.getBoundingClientRect();

      return {
        x: clientRect.left,
        y: clientRect.top
      };
    }

    return { x: 0, y: 0 };
  };
  // after font is uploaded, pass it to parent and close iframe
  const onMessageIframeAddUploadedFonts = useCallback(
    (event) => {
      if (event.data.message === 'onEventUpload') {
        setUserFonts(userFonts.concat(event.data.fonts));
        dispatch(setIframeIsOpened(false));
      }
    },
    [userFonts]
  );
  // listen to message and clean up after
  useEffect(() => {
    window.addEventListener('message', onMessageIframeAddUploadedFonts);
    return () => window.removeEventListener('message', onMessageIframeAddUploadedFonts);
  }, [onMessageIframeAddUploadedFonts]);

  const onMessageCloseIframe = useCallback((event) => {
    if (event.data.message === 'onIframeClose') {
      dispatch(setIframeIsOpened(false));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessageCloseIframe);
    return () => window.removeEventListener('message', onMessageCloseIframe);
  }, [onMessageCloseIframe]);

  return (
    <div id='studio-editor'>
      {/* LOAD GOOGLE FONTS CHOOSEN BY USER */}
      {getFontsData('google')?.length > 0 && (
        <GoogleFontLoader fonts={getFontsData('google')} subsets={['cyrillic-ext', 'greek']} />
      )}
      {getFontsData('external')?.length > 0 &&
        getFontsData('external').map((externalFont) => (
          <Style hasSourceMap={false} key={externalFont.name}>{`
         @font-face {
            font-family: ${externalFont.name};
            src: url(${externalFont.source})
          }
        `}</Style>
        ))}
      {iframeIsOpened && (
        <iframe
          id='fontupload-iframe'
          src={window._env_.REACT_ENV_FONT_UPLOAD_IFRAME_URL + currentAccountId}
          title='fontUploader'
          style={{
            zIndex: 9999,
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            border: 'none'
          }}
        />
      )}
      {/* TOP MENU */}
      <TopMenuContainer topHeaderRef={topHeaderRef} />
      {/* LEFT MENU  */}
      <StudioLeftMenu
        leftMenuTabRef={leftMenuTabRef}
        leftNavRef={leftNavRef}
        changeDcoSourceNameRef={changeDcoSourceNameRef}
      />
      <div
        className='canvas'
        style={{
          backgroundColor: userSettings.background,
          pointerEvents: editDatasourceModalOpened ? 'none' : 'auto'
        }}
        onMouseDown={canvasIsMovable.space ? mousedownCanvasHandler : undefined}
        onMouseUp={canvasIsMovable.space ? mouseupCanvasHandler : undefined}
        onMouseMove={canvasIsMovable.space ? mousemoveCanvasHandler : undefined}>
        {editDatasourceModalOpened && (
          <EditDatasourceModal
            modalId='dco-source-name-change'
            id='dco-source-name-change'
            featureRef={changeDcoSourceNameRef}
          />
        )}

        {quickExportBigMenuOpened && (
          <QuickExportTypeSelection
            featureRef={quickExportRef}
            allOptionsEnabled={isAuthorized({ featureFlag: 'quick-export-extended-access' })}
            onCloseIconClick={(e) => {
              dispatch(resetSelectedQuickExportType());
              dispatch(setQuickExportBigMenuOpened(false));
              capturePosthogData({
                event: 'close-quick-export-type-selection',
                type: 'quick-export',
                selectedExport: selectedQuickExportType.id,
                menu: 'right'
              });
            }}
          />
        )}
        {selectedFormat && (
          <Dropzone
            onItemDropped={(e, object) => handleDropzoneDrop(e, object)}
            dropEffect='copy'
            disabled={editorType === 'banner'}>
            <div className='canvas-actions' style={canvasRealStyle}>
              <Banner
                isEditingTextEnabled={isEditingTextEnabled}
                isReadonlyTextEnabled={isReadonlyTextEnabled}
                toolbarRef={toolbarRef}
                onIsTextEditingEnabled={(status) => onIsTextEditingEnabled(status)}
                excludedRefs={[
                  topHeaderRef,
                  leftNavRef,
                  leftMenuTabRef,
                  rightMenuTabRef,
                  timelineContainerRef,
                  quickExportRef,
                  changeDcoSourceNameRef
                ]}
                excludedContentEditableRefs={[]}
                onStopTimeline={() => dispatch(stopAnimations())}
                bannerOverflowHidden={userSettings.overflow}
                canvasIsMovable={canvasIsMovable.space}
                userSettings={userSettings}
              />
            </div>
          </Dropzone>
        )}
      </div>
      {/* RIGHT MENU  */}
      <StudioRightMenu
        rightMenuTabRef={rightMenuTabRef}
        userFonts={userFonts}
        setUserFonts={setUserFonts}
        googleFonts={googleFonts}
        onIsTextEditingEnabled={onIsTextEditingEnabled}
        isReadonlyTextEnabled={isReadonlyTextEnabled}
        setIsReadonlyTextEnabled={setIsReadonlyTextEnabled}
        toolbarRef={toolbarRef}
        isEditingTextEnabled={isEditingTextEnabled}
        stopAnimations={() => dispatch(stopAnimations())}
        openedEffects={openedEffects}
        setOpenedEffects={setOpenedEffects}
        openedAnimations={openedAnimations}
        setOpenedAnimations={setOpenedAnimations}
      />
      {/* TIMELINE */}
      <Timeline
        timelineContainerRef={timelineContainerRef}
        stopAnimations={() => stopAnimations()}
        buildAnimations={() => dispatch(buildAnimations())}
        setTemplateDuration={(length) => {
          dispatch(setTemplateDuration(parseFloat(length).toFixed(1), presentLayers));
        }}
        setOpenedAnimations={setOpenedAnimations}
        setOpenedEffects={setOpenedEffects}
        openedAnimations={openedAnimations}
        openedEffects={openedEffects}
      />

      {isAuthorized({ featureFlag: 'banner-ruler' }) && userSettings.ruler && (
        <>
          <Ruler axi='x' zeroPosition={rulerPosition.x} />
          <Ruler axi='y' zeroPosition={rulerPosition.y} />
          {template.formats
            .find((format) => format.id === selectedFormatId)
            ?.guidelines?.customGuides.map((customGuide) => (
              <CustomGuide
                key={customGuide.uuid}
                customGuide={customGuide}
                rulerPosition={rulerPosition}
              />
            ))}
        </>
      )}
    </div>
  );
}

function StudioLeftMenu({ leftMenuTabRef, leftNavRef, changeDcoSourceNameRef }) {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const capturePosthogData = usePosthogCapture();
  const { keycloak } = useKeycloak();

  const { template, dco } = useSelector((state) => state);
  const { dcoSelection, activeMenu } = dco;

  const { campaignId, selectedFormatId, selectedFormat, editorType, menus, studio } = useSelector(
    (state) => state.editorSession
  );
  const { selectedFonts, presentLayers } = studio;
  const { leftMenu } = menus;

  const currentAccountId = useSelector((state) => state.auth.currentAccountId);
  const user = useSelector((state) => state.auth.user);
  const currentAccount = user.accounts.find((usrAcc) => usrAcc.account.id === currentAccountId);

  const isLeftMenuDisabled = selectedFormat === null;

  const [dcoMenuOpened, setDCOMenuOpened] = useState({
    dynamic: true,
    parameter: true,
    custom: true
  });

  const formatsData = {
    title: editorType === 'banner' ? 'Creatives' : 'Formats',
    tooltip: 'Generate banner formats',
    icon: (
      <FormatsIcon
        className={
          isLeftMenuDisabled && leftMenu.activeButton !== 'banner-formats' ? 'pulsingButton' : ''
        }
        fill={
          leftMenu.activeButton === 'banner-formats' ||
          leftMenu.hoverActiveButton === 'banner-formats'
            ? '#4792E2'
            : 'white'
        }
      />
    ),
    alt: 'generate formats',
    posthogData: {
      event: 'open-submenu',
      type: 'format',
      menu: 'left',
      submenu: 'formats'
    },
    id: 'banner-formats',
    hidden: false,
    disabled: false,
    highlighted: !!(isLeftMenuDisabled && leftMenu.activeButton !== 'banner-formats')
  };

  const textLayerData = {
    title: 'Text',
    tooltip: 'Add text layer',
    icon: (
      <TextIcon
        height='18'
        width='18'
        fill={
          leftMenu.activeButton === 'text' || leftMenu.hoverActiveButton === 'text'
            ? '#4792E2'
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'Text',
    object_type: getDefaultLayerObject('text'),
    posthogData: {
      event: 'add-element',
      type: 'element',
      element_type: 'text',
      menu: 'left',
      dragndrop: false
    },
    id: 'text',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };

  const rectangleLayerData = {
    title: 'Rectangle',
    tooltip: 'Add rectangle layer',
    icon: (
      <RectangleIcon
        height='18'
        width='18'
        fill={
          leftMenu.activeButton === 'rectangle' || leftMenu.hoverActiveButton === 'rectangle'
            ? '#4792E2'
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'Rectangle',
    object_type: getDefaultLayerObject('rectangle'),
    posthogData: {
      event: 'add-element',
      type: 'element',
      element_type: 'rectangle',
      menu: 'left',
      dragndrop: false
    },
    id: 'rectangle',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };

  const circleLayerData = {
    title: 'Circle',
    tooltip: 'Add circle layer',
    icon: (
      <CircleIcon
        height='18'
        width='18'
        fill={
          leftMenu.activeButton === 'circle' || leftMenu.hoverActiveButton === 'circle'
            ? '#4792E2'
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'Circle',
    object_type: getDefaultLayerObject('circle'),
    posthogData: {
      event: 'add-element',
      type: 'element',
      element_type: 'circle',
      menu: 'left',
      dragndrop: false
    },
    id: 'circle',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };

  const assetsData = {
    title: 'Assets',
    tooltip: 'Image assets',
    icon: (
      <ImagesIcon
        fill={
          leftMenu.activeButton === 'image-assets' || leftMenu.hoverActiveButton === 'image-assets'
            ? '#4792E2'
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'image assets',
    posthogData: {
      event: 'open-submenu',
      type: 'assets',
      menu: 'left',
      submenu: 'assets'
    },
    id: 'image-assets',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };

  const dynamicData = {
    title: 'Dynamic',
    tooltip: 'Manage dynamic sources',
    icon: (
      <DynamicIcon
        width={18}
        height={18}
        fill={
          leftMenu.activeButton === 'dynamic' || leftMenu.hoverActiveButton === 'dynamic'
            ? theme.colors.dcoPurple
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'manage dynamic sources',
    posthogData: {
      event: 'open-submenu',
      type: 'dynamic',
      menu: 'left',
      submenu: 'dynamic'
    },
    id: 'dynamic',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };
  const logOutButtonData = {
    title: 'Logout',
    tooltip: 'Logout',
    icon: <LogOutIcon className='icon-hover' />,
    alt: 'logout',
    posthogData: {
      event: 'log-out',
      type: 'redirect',
      menu: 'left'
    },
    id: 'logout'
  };
  const goToStudioButtonData = {
    title: 'Studio',
    tooltip: 'Go to studio',
    icon: <GoToStudioOrCreativeManagerIcon className='icon-hover' />,
    alt: 'studio',
    posthogData: {
      event: 'redirect-to-studio',
      type: 'redirect',
      menu: 'left'
    },
    id: 'goToStudio',
    href: window._env_.REACT_ENV_STUDIO_URL
  };
  const goToCreativeManagerButtonData = {
    title: 'Manager',
    tooltip: 'Go to Creative Manager',
    icon: <GoToStudioOrCreativeManagerIcon className='icon-hover' />,
    alt: 'zuuvi app',
    posthogData: {
      event: 'redirect-to-creative-manager',
      type: 'redirect',
      menu: 'left'
    },
    id: 'goToCreativeManager',
    href: window._env_.REACT_ENV_APP_URL
  };

  let LEFT_BUTTONS_MENU_DATA = [];
  let LEFT_BUTTONS_NAV_DATA = [];

  const hasAccessToNewStudio = currentAccount.role.permissions.includes('studio');

  if (editorType === 'banner') {
    LEFT_BUTTONS_MENU_DATA = [formatsData, assetsData];

    if (
      dcoSelection.dynamicSourceIncluded ||
      dcoSelection.parameterSourceIncluded ||
      dcoSelection.customSourceIncluded
    ) {
      LEFT_BUTTONS_MENU_DATA.push(dynamicData);
    }

    if (hasAccessToNewStudio) {
      LEFT_BUTTONS_NAV_DATA = [goToStudioButtonData, logOutButtonData];
    }
    if (!hasAccessToNewStudio) {
      LEFT_BUTTONS_NAV_DATA = [logOutButtonData];
    }
  } else {
    LEFT_BUTTONS_MENU_DATA = [
      formatsData,
      textLayerData,
      rectangleLayerData,
      circleLayerData,
      assetsData,
      dynamicData
    ];

    LEFT_BUTTONS_NAV_DATA = [goToCreativeManagerButtonData, logOutButtonData];
  }

  const DCO_DATA = isAuthorized({ feature: 'dco' })
    ? {
        id: 'add-source-dco',
        title: 'DCO - Display',
        icon: <MainDcoIcon />,
        onButtonClick: () => {
          capturePosthogData({ event: 'show-add-source-large-menu', type: 'dco' });
          dispatch(
            setLeftMenu({
              ...leftMenu,
              dynamicLargeMenu: 'dco'
            })
          );
        },
        isLocked: false
      }
    : {
        id: 'add-source-dco-locked',
        title: 'DCO - Display',
        icon: <MainDcoIconLocked />,
        onLockedBoxClick: () => {
          capturePosthogData({ event: 'show-unlock-dco-popup-modal', type: 'dco' });
          window.open('https://www.zuuvi.com/book-dynamic-demo', '_blank');
        },
        isLocked: true
      };

  const DPA_DATA = isAuthorized({ feature: 'dpa' })
    ? {
        id: 'add-source-dpa',
        title: 'Catalog Ads',
        icon: <MainDpaIcon />,
        onButtonClick: () => {
          capturePosthogData({ event: 'show-add-source-large-menu', type: 'dpa' });
          dispatch(
            setLeftMenu({
              ...leftMenu,
              dynamicLargeMenu: 'dpa'
            })
          );
        },
        isLocked: false
      }
    : {
        id: 'add-source-dpa-locked',
        title: 'Catalog Ads',
        icon: <MainDpaIconLocked />,
        onLockedBoxClick: () => {
          capturePosthogData({ event: 'show-unlock-dpa-popup-modal', type: 'dpa' });
          window.open('https://www.zuuvi.com/book-dynamic-demo', '_blank');
        },
        isLocked: true
      };

  const DYNAMIC_MENU_DATA = [DCO_DATA, DPA_DATA];

  function removeDcoFromLayers(dynamicId, resetState) {
    //  Reset dco source on layers

    template.formats.forEach((format) => {
      Object.entries(format.object_data.layers).forEach(([layerId, layer]) => {
        if (JSON.stringify(layer).includes(dynamicId)) {
          if ('text' in layer.settings) {
            dispatch(
              updateLayerSettings(format.id, layerId, {
                text: removeDcoTagFromTextById(layer.settings.text, dynamicId)
              })
            );
          } else {
            dispatch(
              updateLayerDCO(layerId, {
                dynamic_key: 'None',
                dynamic_uuid: ''
              })
            );
          }
        }
      });
    });

    dispatch(rerenderSlateEditor());
    dispatch(rerenderSlate());
    dispatch(
      setDcoSelection({
        ...dcoSelection,
        ...resetState
      })
    );
  }
  const deleteWarningRef = useRef();
  const deleteDynamicButtonRef = useRef();
  const deleteParameterButtonRef = useRef();
  const deleteCustomButtonRef = useRef();
  const [showWarning, setShowWarning] = useState('');

  const renderDcoMenu = () => {
    if (activeMenu.menu === 'create') {
      return <CreateMenu modalRef={changeDcoSourceNameRef} />;
    }
    if (activeMenu.menu === 'configure') {
      return <ConfigureMenu modalRef={changeDcoSourceNameRef} />;
    }

    return <OverviewMenu modalRef={changeDcoSourceNameRef} />;
  };

  useClickOutside(deleteWarningRef, () => setShowWarning(''), [
    deleteDynamicButtonRef,
    deleteParameterButtonRef,
    deleteCustomButtonRef
  ]);

  return (
    <div className='left-nav-wrapper' ref={leftMenuTabRef}>
      {leftMenu.dynamicLargeMenu !== '' && renderDcoMenu()}

      <div className='main-nav left-nav' ref={leftNavRef}>
        {isAuthorized({ featureFlag: 'santa-hat-logo' }) ? (
          <ZuuviLogoSantaHat className='zuuvi-logo' />
        ) : (
          <ZuuviLogo className='zuuvi-logo' />
        )}
        {LEFT_BUTTONS_MENU_DATA.map(
          (button) =>
            !button.hidden &&
            (button.id === 'text' || button.id === 'rectangle' || button.id === 'circle' ? (
              <Draggable
                key={button.title}
                dataItem={{ type: 'element', name: button.id }}
                draggedObject={button.object_type}
                dropEffect='copy'>
                <MediumIconButton
                  id={button.id}
                  tooltip={button.tooltip}
                  text={button.title}
                  disabled={button.disabled}
                  icon={button.icon}
                  onHandleMouseEnter={() =>
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        hoverActiveButton: button.id
                      })
                    )
                  }
                  onHandleMouseLeave={() =>
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        hoverActiveButton: ''
                      })
                    )
                  }
                  onHandleClick={() => {
                    capturePosthogData(button.posthogData);
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        leftNavTabActive: '',
                        activeButton: ''
                      })
                    );
                    if (button.id === 'text') {
                      button.object_type.settings.font = selectedFonts.find(
                        (font) => font.name === button.object_type.settings.font.name
                      )
                        ? selectedFonts.find(
                            (font) => font.name === button.object_type.settings.font.name
                          )
                        : {
                            uuid: uuidv4(),
                            name: 'Arial',
                            reference_type: 'web_safe'
                          };
                    }
                    dispatch(addLayer(button.object_type, selectedFormatId));
                  }}
                />
              </Draggable>
            ) : (
              <MediumIconButton
                buttonRef={button.buttonRef}
                id={button.id}
                key={button.id}
                tooltip={button.tooltip}
                text={button.title}
                disabled={button.disabled}
                icon={button.icon}
                greyText={button.greyText}
                onHandleMouseEnter={() =>
                  dispatch(
                    setLeftMenu({
                      ...leftMenu,
                      hoverActiveButton: button.id
                    })
                  )
                }
                onHandleMouseLeave={() =>
                  dispatch(
                    setLeftMenu({
                      ...leftMenu,
                      hoverActiveButton: ''
                    })
                  )
                }
                onHandleClick={() => {
                  capturePosthogData(button.posthogData);
                  if (leftMenu.leftNavTabActive === button.id) {
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        activeButton: '',
                        leftNavTabActive: ''
                      })
                    );
                  } else {
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        activeButton: button.id,
                        leftNavTabActive: button.id
                      })
                    );
                  }
                }}
              />
            ))
        )}
        <BreakTheWorld />

        <div className='left-nav-bottom-buttons'>
          {LEFT_BUTTONS_NAV_DATA.map((button, i) =>
            button.id === 'logout' ? (
              <MediumIconButton
                id={button.id}
                key={button.id}
                tooltip={button.tooltip}
                text={button.title}
                disabled={button.disabled}
                icon={button.icon}
                onHandleMouseEnter={() =>
                  dispatch(
                    setLeftMenu({
                      ...leftMenu,
                      hoverActiveButton: button.id
                    })
                  )
                }
                onHandleMouseLeave={() =>
                  dispatch(
                    setLeftMenu({
                      ...leftMenu,
                      hoverActiveButton: ''
                    })
                  )
                }
                onHandleClick={() => {
                  capturePosthogData(button.posthogData);
                  if (button.id === 'logout') {
                    keycloak.logout();
                    posthog.reset();
                  }
                }}
              />
            ) : (
              <a
                className='icon-link'
                href={button.href}
                target='_blank'
                rel='noreferrer'
                key={button.posthogData.event + i}>
                <MediumIconButton
                  id={button.id}
                  onHandleClick={() => {
                    capturePosthogData(button.posthogData);
                  }}
                  tooltip={button.tooltip}
                  text={button.title}
                  disabled={button.disabled}
                  icon={button.icon}
                />
              </a>
            )
          )}
        </div>
      </div>

      {leftMenu.leftNavTabActive !== '' && (
        <div className='left-tab-open'>
          <div className='close-cross-icon-wraper'>
            <button
              type='button'
              onClick={() =>
                dispatch(
                  setLeftMenu({
                    ...leftMenu,
                    activeButton: '',
                    leftNavTabActive: ''
                  })
                )
              }
              id='close-left-submenu'>
              <CloseIcon className='margin-right-pointer icon-hover' />
            </button>
          </div>
          <div className='scroll-container'>
            {leftMenu.leftNavTabActive === 'image-assets' && (
              <AssetsMenu currentLayers={presentLayers} campaignId={campaignId} />
            )}
            {leftMenu.leftNavTabActive === 'dynamic' && (
              <div>
                {editorType !== 'banner' &&
                  !dcoSelection.dynamicSourceIncluded &&
                  !dcoSelection.parameterSourceIncluded &&
                  !dcoSelection.customSourceIncluded && (
                    <StyledSectionWrap>
                      {DYNAMIC_MENU_DATA.map((dynamicType) => (
                        <DynamicTypeCard
                          key={dynamicType.id}
                          id={dynamicType.id}
                          onLockedBoxClick={dynamicType.onLockedBoxClick}
                          title={dynamicType.title}
                          icon={dynamicType.icon}
                          onButtonClick={dynamicType.onButtonClick}
                          isLocked={dynamicType.isLocked}
                        />
                      ))}
                    </StyledSectionWrap>
                  )}

                {editorType !== 'banner' &&
                  dcoSelection.dynamicSource.export !== 'dpa' &&
                  (dcoSelection.dynamicSourceIncluded ||
                    dcoSelection.parameterSourceIncluded ||
                    dcoSelection.customSourceIncluded) && (
                    <StyledSectionWrap>
                      <button
                        type='button'
                        title={
                          (dcoSelection.dynamicSourceIncluded &&
                            dcoSelection.parameterSourceIncluded) ||
                          dcoSelection.customSourceIncluded
                            ? 'The maximum of allowed source type is already selected, remove it to select another.'
                            : 'Add source'
                        }
                        id='left-menu-add-dco'
                        className={
                          (dcoSelection.dynamicSourceIncluded &&
                            dcoSelection.parameterSourceIncluded) ||
                          dcoSelection.customSourceIncluded
                            ? 'add-dco-button-disabled'
                            : 'add-dco-button'
                        }
                        disabled={
                          (dcoSelection.dynamicSourceIncluded &&
                            dcoSelection.parameterSourceIncluded) ||
                          dcoSelection.customSourceIncluded
                        }
                        onClick={() => {
                          capturePosthogData({
                            event: 'show-add-source-large-menu',
                            type: 'dco'
                          });
                          dispatch(
                            setLeftMenu({
                              ...leftMenu,
                              dynamicLargeMenu: 'dco'
                            })
                          );
                        }}>
                        <p>Add source</p>
                      </button>
                    </StyledSectionWrap>
                  )}
                {dcoSelection.dynamicSourceIncluded && (
                  <StyledSectionWrap>
                    <div className='dco-select-icon-name-wrapper'>
                      {dcoSelection.dynamicSource.export !== 'dpa' ? (
                        <MainDcoIcon className='dco-select-icon' />
                      ) : (
                        <MainDpaIcon className='dco-select-icon' />
                      )}
                      <StyledDynamicFeedOverviewTitle>
                        {dcoSelection.dynamicSource.name}
                      </StyledDynamicFeedOverviewTitle>
                    </div>
                    <div>
                      <div style={{ display: 'flex', height: 17, alignItems: 'center' }}>
                        <button
                          type='button'
                          id='dco-preview-dynamic-source'
                          onClick={() => {
                            dispatch(
                              setDcoSelection({
                                ...dcoSelection,
                                dynamicSourcePreview: !dcoSelection.dynamicSourcePreview
                              })
                            );
                          }}>
                          {dcoSelection.dynamicSourcePreview ? (
                            <EyeIcon
                              height={14}
                              width={21}
                              className='eye-icon-dco'
                              fill={theme.colors.dcoPurple}
                            />
                          ) : (
                            <HiddenEyeIcon height={17} width={21} />
                          )}
                        </button>
                        {editorType !== 'banner' && (
                          <button
                            type='button'
                            style={{ height: 17 }}
                            ref={deleteDynamicButtonRef}
                            id='dco-remove-dynamic-source'
                            className='dco-select-remove'
                            onClick={(e) => {
                              showWarning === 'dynamic-source'
                                ? setShowWarning('')
                                : setShowWarning('dynamic-source');
                              e.stopPropagation();
                            }}>
                            <TrashcanIcon className='trash-icon-dco' />
                          </button>
                        )}
                      </div>
                      {showWarning === 'dynamic-source' && (
                        <DeleteWarning
                          ref={deleteWarningRef}
                          right='10px'
                          data-cy='option-delete-warning'
                          className='format-option-item delete'
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setShowWarning('');
                            removeDcoFromLayers(dcoSelection.dynamicSource.id, {
                              dynamicSource: {},
                              dynamicSourceFull: {},
                              dynamicSourceIncluded: false,
                              dynamicSourcePreview: false,
                              dynamicSourceSelectedRow: 0
                            });
                          }}>
                          <b>DELETE</b>
                        </DeleteWarning>
                      )}
                    </div>
                  </StyledSectionWrap>
                )}
                {dcoSelection.parameterSourceIncluded && (
                  <StyledSectionWrap>
                    <div className='dco-select-icon-name-wrapper'>
                      <MainDcoIcon className='dco-select-icon' />
                      <StyledDynamicFeedOverviewTitle>
                        {dcoSelection.parameterSource.name}
                      </StyledDynamicFeedOverviewTitle>
                    </div>
                    <div>
                      <div style={{ display: 'flex', height: 17, alignItems: 'center' }}>
                        <button
                          type='button'
                          id='dco-preview-parameter-source'
                          onClick={() => {
                            dispatch(
                              setDcoSelection({
                                ...dcoSelection,
                                parameterSourcePreview: !dcoSelection.parameterSourcePreview
                              })
                            );
                          }}>
                          {dcoSelection.parameterSourcePreview ? (
                            <EyeIcon
                              height={14}
                              width={21}
                              className='eye-icon-dco'
                              fill={theme.colors.dcoPurple}
                            />
                          ) : (
                            <HiddenEyeIcon height={17} width={21} />
                          )}
                        </button>
                        {editorType !== 'banner' && (
                          <button
                            type='button'
                            style={{ height: 17 }}
                            id='dco-remove-parameter-source'
                            className='dco-select-remove'
                            ref={deleteParameterButtonRef}
                            onClick={(e) => {
                              showWarning === 'parameter-source'
                                ? setShowWarning('')
                                : setShowWarning('parameter-source');
                              e.stopPropagation();
                            }}>
                            <TrashcanIcon className='trash-icon-dco' />
                          </button>
                        )}
                      </div>
                      {showWarning === 'parameter-source' && (
                        <DeleteWarning
                          ref={deleteWarningRef}
                          right='10px'
                          data-cy='option-delete-warning'
                          className='format-option-item delete'
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setShowWarning('');
                            removeDcoFromLayers(dcoSelection.parameterSource.id, {
                              parameterSource: {},
                              parameterSourceIncluded: false,
                              parameterSourcePreview: false
                            });
                          }}>
                          <b>DELETE</b>
                        </DeleteWarning>
                      )}
                    </div>
                  </StyledSectionWrap>
                )}
                {dcoSelection.customSourceIncluded && (
                  <StyledSectionWrap>
                    <div className='dco-select-icon-name-wrapper'>
                      <MainDcoIcon className='dco-select-icon' />
                      <StyledDynamicFeedOverviewTitle>
                        {dcoSelection.customSource.name}
                      </StyledDynamicFeedOverviewTitle>
                    </div>
                    <div>
                      <div style={{ display: 'flex', height: 17, alignItems: 'center' }}>
                        <button
                          type='button'
                          id='dco-preview-custom-source'
                          onClick={() => {
                            dispatch(
                              setDcoSelection({
                                ...dcoSelection,
                                customSourcePreview: !dcoSelection.customSourcePreview
                              })
                            );
                          }}>
                          {dcoSelection.customSourcePreview ? (
                            <EyeIcon
                              height={14}
                              width={21}
                              className='eye-icon-dco'
                              fill={theme.colors.dcoPurple}
                            />
                          ) : (
                            <HiddenEyeIcon height={17} width={21} />
                          )}
                        </button>
                        {editorType !== 'banner' && (
                          <button
                            type='button'
                            id='dco-remove-custom-source'
                            style={{ height: 17 }}
                            className='dco-select-remove'
                            ref={deleteCustomButtonRef}
                            onClick={(e) => {
                              showWarning === 'custom-source'
                                ? setShowWarning('')
                                : setShowWarning('custom-source');
                              e.stopPropagation();
                            }}>
                            <TrashcanIcon className='trash-icon-dco' />
                          </button>
                        )}
                      </div>
                      {showWarning === 'custom-source' && (
                        <DeleteWarning
                          ref={deleteWarningRef}
                          right='10px'
                          data-cy='option-delete-warning'
                          className='format-option-item delete'
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setShowWarning('');
                            removeDcoFromLayers(dcoSelection.customSource.id, {
                              customSource: {},
                              customSourceIncluded: false,
                              customSourcePreview: false
                            });
                          }}>
                          <b>DELETE</b>
                        </DeleteWarning>
                      )}
                    </div>
                  </StyledSectionWrap>
                )}
                {/* Preview content */}
                {dcoSelection.dynamicSourceIncluded && (
                  <>
                    <ToggleRevealSectionButton
                      id='dco-open-dynamic-source-content-preview'
                      handleOnClick={() => {
                        capturePosthogData({
                          event: 'open-preview',
                          type: 'dco',
                          content_origin: 'source',
                          is_open: !dcoMenuOpened.dynamic
                        });
                        setDCOMenuOpened({
                          ...dcoMenuOpened,
                          dynamic: !dcoMenuOpened.dynamic
                        });
                      }}
                      isOpened={dcoMenuOpened.dynamic}>
                      <p>Preview Your Products</p>
                    </ToggleRevealSectionButton>
                    <StyledSectionWrap>
                      {dcoMenuOpened.dynamic && <PreviewDynamicProducts />}
                    </StyledSectionWrap>
                  </>
                )}
                {dcoSelection.parameterSourceIncluded && (
                  <>
                    <ToggleRevealSectionButton
                      id='dco-open-dynamic-parameter-content-preview'
                      handleOnClick={() => {
                        capturePosthogData({
                          event: 'open-preview',
                          type: 'dco',
                          content_origin: 'parameter',
                          is_open: !dcoMenuOpened.parameter
                        });
                        setDCOMenuOpened({
                          ...dcoMenuOpened,
                          parameter: !dcoMenuOpened.parameter
                        });
                      }}
                      isOpened={dcoMenuOpened.parameter}>
                      <p>Preview Your Products</p>
                    </ToggleRevealSectionButton>

                    {dcoMenuOpened.parameter && (
                      <DCOMiniTable
                        data={dcoSelection.parameterSource.keys}
                        size={dcoSelection.parameterSource.keys.length}
                        type='parameter'
                      />
                    )}
                  </>
                )}
                {dcoSelection.customSourceIncluded && (
                  <>
                    <ToggleRevealSectionButton
                      id='dco-open-dynamic-custom-content-preview'
                      handleOnClick={() => {
                        capturePosthogData({
                          event: 'open-preview',
                          type: 'dco',
                          content_origin: 'custom',
                          is_open: !dcoMenuOpened.custom
                        });
                        setDCOMenuOpened({
                          ...dcoMenuOpened,
                          custom: !dcoMenuOpened.custom
                        });
                      }}
                      isOpened={dcoMenuOpened.parameter}>
                      <p className='text-small'>Preview Your Products</p>
                    </ToggleRevealSectionButton>
                    {dcoMenuOpened.custom && (
                      <DCOMiniTable
                        data={dcoSelection.customSource.json.keys}
                        size={dcoSelection.customSource.json.keys.length}
                        type='custom'
                      />
                    )}
                  </>
                )}
              </div>
            )}

            {/* DCO END */}
            {leftMenu.leftNavTabActive === 'banner-formats' && <Formats />}
          </div>
        </div>
      )}
    </div>
  );
}

function StudioRightMenu({
  rightMenuTabRef,
  userFonts,
  setUserFonts,
  googleFonts,
  onIsTextEditingEnabled,
  isReadonlyTextEnabled,
  setIsReadonlyTextEnabled,
  toolbarRef,
  isEditingTextEnabled,
  openedEffects,
  setOpenedEffects,
  openedAnimations,
  setOpenedAnimations
}) {
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const { template } = useSelector((state) => state);
  const { selectedFormatId, activeLayer, selectedFormat, editorType, menus, studio } = useSelector(
    (state) => state.editorSession
  );
  const { presentLayers } = studio;
  const { rightMenu } = menus;

  const [showAnimationList, setShowAnimationList] = useState(false);
  const [showEffectsList, setShowEffectsList] = useState(false);

  const isDisabled = selectedFormat === null || editorType === 'banner';

  const onIsReadonlyTextEnabled = (status) => {
    setIsReadonlyTextEnabled(status);
  };

  function addAnimationEditor(layerId, path, type, settings, ease, direction) {
    const animationId = uuidv4();
    const newAnimation = {
      uuid: animationId,
      type,
      direction: direction || 'to',
      time: '0.0',
      ease,
      easeType: 'inOut',
      ...(settings && { settings: { ...settings } }),
      // settings: {...settings && settings},
      only_on_repeat: false,
      duration: 0.5,
      target: layerId
    };
    dispatch(addAnimation(selectedFormatId, layerId, newAnimation));

    setOpenedEffects([...openedEffects, animationId]);
    setOpenedAnimations([...openedAnimations, animationId]);

    dispatch(
      setActiveAnimation({
        uuid: animationId,
        target: layerId,
        index: 0
      })
    );
  }

  const RIGHT_BUTTONS_DATA = [
    {
      text: 'Edit',
      tooltip: 'Editing of elements',
      buttonStyle: { marginTop: 20 },
      icon: (
        <EditIcon
          fill={rightMenu === 'edit' ? '#4792E2' : selectedFormat === null ? 'gray' : 'white'}
          className={selectedFormat === null ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-edit',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'edit'
      },
      id: 'edit',
      disabled: selectedFormat === null,
      hidden: false
    },
    {
      text: 'Animate',
      tooltip: 'Animate elements',
      icon: (
        <AnimateIcon
          fill={rightMenu === 'animate' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-animate',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'animate'
      },
      id: 'animate',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Effects',
      tooltip: 'Element effects',
      icon: (
        <EffectsIcon
          fill={rightMenu === 'effects' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-effects',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'effects'
      },
      id: 'effects',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Clicktag',
      tooltip: 'Clicktag',
      icon: (
        <ClicktagIcon
          fill={rightMenu === 'clicktag' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-clicktag',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'clicktag'
      },
      id: 'clicktag',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Template Settings',
      tooltip: 'Settings for template',
      icon: (
        <TemplateSettingsIcon
          fill={rightMenu === 'template_settings' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      buttonStyle: { marginTop: 7 },
      posthogData: {
        event: 'open-template-settings',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'template-settings'
      },
      id: 'template_settings',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Finalise',
      tooltip: 'Finalise template',
      icon: (
        <FinaliseIcon
          fill={rightMenu === 'finalise' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      buttonStyle: { marginTop: 9 },
      posthogData: {
        event: 'open-finalise',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'finalise'
      },
      id: 'finalise',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Clicktag',
      tooltip: 'Clicktags for banner',
      icon: (
        <ClicktagIcon
          fill={
            rightMenu === 'banner_clicktag_settings'
              ? '#4792E2'
              : selectedFormat === null || editorType !== 'banner'
              ? 'gray'
              : 'white'
          }
          className={selectedFormat === null || editorType !== 'banner' ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-banner-clicktag',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'banner_clicktag-settings'
      },
      id: 'banner_clicktag_settings',
      disabled: selectedFormat === null || editorType !== 'banner',
      hidden: editorType !== 'banner'
    }
  ];

  const animationAndEffectIncluded = () => {
    let isIncluded = { animationsIncluded: false, effectsIncluded: false };
    flat(presentLayers).forEach((layer) => {
      if (activeLayer === layer.uuid) {
        if (layer.animations && layer.animations.length > 0) {
          const effects = layer.animations.filter(
            (an) =>
              an.type === 'fadeTop' ||
              an.type === 'fadeBottom' ||
              an.type === 'pulse' ||
              an.type === 'makeVisible'
          );
          if (effects.length === 0) {
            isIncluded = { animationsIncluded: true, effectsIncluded: false };
          }
          if (effects.length > 0 && effects.length < layer.animations.length) {
            isIncluded = { animationsIncluded: true, effectsIncluded: true };
          }
          if (effects.length === layer.animations.length) {
            isIncluded = { animationsIncluded: false, effectsIncluded: true };
          }
        }
      }
    });
    return isIncluded;
  };
  return (
    <div className='right-nav-wrapper' ref={rightMenuTabRef}>
      <div className='main-nav right-nav'>
        {RIGHT_BUTTONS_DATA.map((button) => (
          <MediumIconButton
            id={button.id}
            disabled={button.disabled}
            buttonStyle={button.buttonStyle}
            hidden={button.hidden}
            text={button.text}
            tooltip={button.tooltip}
            icon={button.icon}
            key={button.id}
            onHandleClick={() => {
              capturePosthogData(button.posthogData);
              dispatch(resetActiveAnimation());
              dispatch(setRightMenu(rightMenu !== button.id ? button.id : ''));
            }}
          />
        ))}
      </div>
      {rightMenu && (
        <div className='right-nav-opened'>
          <div className='scroll-container'>
            {!activeLayer &&
              rightMenu !== 'template_settings' &&
              rightMenu !== 'banner_clicktag_settings' &&
              rightMenu !== 'finalise' &&
              rightMenu !== 'clicktag' && (
                <div className='template-settings-section'>
                  <div className='menu-title'> Please select layer or group.</div>
                </div>
              )}
            {rightMenu === 'edit' && activeLayer && (
              <RightMenu
                isReadonlyTextEnabled={isReadonlyTextEnabled}
                toolbarRef={toolbarRef}
                onIsTextEditingEnabled={(status) => onIsTextEditingEnabled(status)}
                onIsReadonlyTextEnabled={(status) => onIsReadonlyTextEnabled(status)}
                isEditingTextEnabled={isEditingTextEnabled}
                userFonts={userFonts}
                onsetUserFonts={(newFonts) => setUserFonts(newFonts)}
                googleFonts={googleFonts}
              />
            )}
            {rightMenu === 'animate' && editorType !== 'banner' && activeLayer && (
              <>
                <AnimationSelection
                  isIncluded={animationAndEffectIncluded().animationsIncluded}
                  showList={showAnimationList}
                  onSetShowList={(data) => setShowAnimationList(data)}
                  animationTypes='animation'
                  openedAnimations={openedAnimations}
                  onSetOpenedAnimations={(data) => setOpenedAnimations(data)}
                  onAddAnimationEditor={(animationId, settings, ease, direction) => {
                    addAnimationEditor(
                      activeLayer,
                      getPathById(
                        activeLayer,
                        template.formats.find((format) => format.id === selectedFormatId)?.layers ||
                          []
                      ),
                      animationId,
                      settings,
                      ease,
                      direction
                    );
                  }}
                />
              </>
            )}
            {rightMenu === 'effects' && editorType !== 'banner' && activeLayer && (
              <AnimationSelection
                isIncluded={animationAndEffectIncluded().effectsIncluded}
                showList={showEffectsList}
                onSetShowList={(data) => setShowEffectsList(data)}
                animationTypes='effect'
                openedAnimations={openedEffects}
                onSetOpenedAnimations={(data) => setOpenedEffects(data)}
                onAddAnimationEditor={(animationId, settings, ease, direction) => {
                  addAnimationEditor(
                    activeLayer,
                    getPathById(
                      activeLayer,
                      template.formats.find((format) => format.id === selectedFormatId)?.layers ||
                        []
                    ),
                    animationId,
                    settings,
                    ease,
                    direction
                  );
                }}
              />
            )}
            {rightMenu === 'clicktag' && (
              <RightMenuTemplateClicktag
                onSetTemplateDuration={(length) =>
                  dispatch(setTemplateDuration(parseFloat(length).toFixed(1), presentLayers))
                }
                onStopAnimations={() => {
                  dispatch(stopAnimations());
                }}
              />
            )}
            {rightMenu === 'template_settings' && (
              <RightMenuTemplateSettings
                onSetTemplateDuration={(length) =>
                  dispatch(setTemplateDuration(parseFloat(length).toFixed(1), presentLayers))
                }
              />
            )}
            {rightMenu === 'banner_clicktag_settings' && (
              <RightMenuBannerClicktag onStopAnimations={() => dispatch(stopAnimations())} />
            )}
            {rightMenu === 'finalise' && <RightMenuFinalise />}
          </div>
        </div>
      )}
    </div>
  );
}

export default StudioEditor;
