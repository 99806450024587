import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledInputWrapperBig,
  StyledInputLabelWrapper,
  StyledCheckbox,
  StyledTextarea
} from '../common/styled/AttributeInputs';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';
import DeleteWarning from '../common/styled/DeleteWarning';
import InputNumber from '../common/InputNumber';
import {
  EyeIcon,
  HiddenEyeIcon,
  TrashcanIcon,
  AttributeOpenedIcon,
  EditPenSmall
} from '../../assets/icons';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import { flat } from '../../../helpers';
import { useClickOutside, renderIcon } from '../../assets/utils';
import {
  updateContentSpecificClicktag,
  updateContentSpecificClicktagOnLayer,
  deleteContentSpecificClicktag
} from '../../redux/slices/template';
import {
  updateClicktagPreviewData,
  removePreviewedClicktagFromData,
  setClicktagErrorMessage,
  resetClicktagErrorMessage
} from '../../redux/slices/editorSession';
import BaseButton from '../common/BaseButton';
import { zuuviColors } from '../../../utils/constants/colors';

const SyledArrowDownIconWrapper = styled.div`
  margin-left: 215px;
  position: absolute;
  display: flex;
`;
const ClicktagTitleWrapper = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
`;
const ClicktagTitleEditableInput = styled.input`
  width: 150px;
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid #7a7a7a;
  color: white;
  font-size: 13px;
`;

const StyledDropdownClosed = styled.div`
  margin-top: 7px;
  border: 1px solid #4a90e2;
  position: relative;
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
`;
const StyledDropdownOpened = styled.div`
  max-height: 187px;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 219px;
  border-radius: 4px;
  background: #313131;
  border: 1px solid #747474;
  margin-top: 7px;
  padding: 12px 0px 0px 15px;
`;
const DropdownLayersWrapper = styled.div`
  max-height: 134px;
  overflow-y: scroll;
`;
const NestedLayerVerticalBar = styled.div`
  width: 1px;
  height: 25px;
  background: #7c7c7c;
  margin-right: 15px;
`;
const SelectLayerOptionWrapper = styled.div`
  margin-left: ${(props) => (props.isNested ? '7px' : '0px')};
  display: flex;
  flex-wrap: wrap;
  max-width: 200px;
  margin-bottom: ${(props) => (props.isNested && !props.hasMarginBottom ? ' 0px' : '10px')};
  align-items: center;
`;

const SmallButtonWrapper = styled.div`
  width: 204px;
  display: flex;
  justify-content: end;
`;

const FadeoutWrapper = styled.div`
  position: relative;
`;

const FadeoutBox = styled.div`
  position: absolute;
  width: 200px;
  height: 20px;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(49, 49, 49, 0) 0%, #313131 100%);
`;

function SelectLayer(props) {
  const { layer, isNested, clicktag, hasMarginBottom } = props;
  const { uuid, start_time, stop_time } = clicktag;
  const dispatch = useDispatch();
  const selectedFormatId = useSelector((state) => state.editorSession.selectedFormatId);
  const formats = useSelector((state) => state.template.formats);
  const selectedFormat = formats.find((format) => format.id === selectedFormatId);
  // get all clicktags
  const linkedClicktags =
    useSelector((state) => state.template.object_data.settings.linked_clicktags) ?? true;
  const templateClicktags = useSelector((state) => state.template.object_data.clicktags);
  const formatClicktags = selectedFormat?.object_data?.clicktags;
  const clicktags = linkedClicktags ? templateClicktags : formatClicktags;
  // get clicktags on layer
  const layerClicktags = layer.settings?.clicktag;
  // check if the layer clicktags contain the current one
  const containsClicktag = !!layerClicktags?.includes(uuid);
  // get intervals of clicktags assigned to current layers
  const getIntervalsOnLayer = () => {
    const layerClicktagData = [];
    // check if there is a clicktag to compare
    if (layerClicktags && layerClicktags.length > 0) {
      // if there is get the data for the clicktags
      layerClicktags.forEach((assignedClicktagId) => {
        const layerClicktag = clicktags.find((item) => item.uuid === assignedClicktagId);
        const index = layerClicktagData.findIndex((object) => object.uuid === assignedClicktagId);

        // if the clicktag is not in the array yet push the data
        if (index === -1) {
          layerClicktagData.push({
            uuid: assignedClicktagId,
            start: parseFloat(layerClicktag.start_time),
            end: parseFloat(layerClicktag.stop_time)
          });
          return layerClicktagData;
        }
      });
    }
    return layerClicktagData;
  };

  const checkLayerIntervalsOverlap = () => {
    let overlaps = false;
    let intervals = [];
    const layerIntervals = getIntervalsOnLayer();
    const index = layerIntervals.findIndex((object) => object.uuid === uuid);

    // add current clicktags interval to the existing array
    if (layerIntervals.length > 0 && index === -1) {
      intervals = getIntervalsOnLayer().concat({
        uuid: clicktag.uuid,
        start: parseFloat(clicktag.start_time),
        end: parseFloat(clicktag.stop_time)
      });
    }
    // if interval exists
    if (intervals.length > 0) {
      // sort intervals based on start time
      intervals?.sort((a, b) => a.start - b.start);
      // check if intervals overlap
      for (let i = 0; i < intervals.length - 1; i++) {
        if (intervals[i].end > intervals[i + 1].start) {
          // true if they overlap
          overlaps = true;
        }
      }
    }
    // if they don't overlap
    return overlaps;
  };

  return (
    <div id={`assign-clicktag-to-layer-selection-${layer.uuid}`}>
      <SelectLayerOptionWrapper
        isNested={isNested}
        hasMarginBottom={hasMarginBottom}
        className='layer-option-wrapper'>
        {isNested && <NestedLayerVerticalBar />}
        <StyledCheckbox
          id={`right-submenu-select-layer-content-specific-clicktag-${layer.uuid}`}
          type='checkbox'
          data-cy='assign-clicktag-checkbox'
          disabled={checkLayerIntervalsOverlap() && !containsClicktag}
          checked={containsClicktag}
          onChange={() => {
            if (containsClicktag) {
              dispatch(
                updateContentSpecificClicktagOnLayer(
                  layer.uuid,
                  uuid,
                  'removeClicktag',
                  selectedFormatId
                )
              );
            } else {
              // if intervals overlap do something
              dispatch(
                updateContentSpecificClicktagOnLayer(
                  layer.uuid,
                  uuid,
                  'addClicktag',
                  selectedFormatId
                )
              );
            }
          }}
        />
        {renderIcon(layer.type, false)}
        {/* change styling if they overlap */}
        <p
          style={{
            ...(checkLayerIntervalsOverlap() && !containsClicktag && { color: '#747474' })
          }}>
          {layer.name}
        </p>
      </SelectLayerOptionWrapper>
      {layer.layers?.length > 0 &&
        layer.layers.map((childLayer, i) => (
          <SelectLayer
            layer={childLayer}
            isNested
            key={childLayer.uuid}
            clicktag={clicktag}
            hasMarginBottom={i === layer.layers.length - 1}
          />
        ))}
    </div>
  );
}

function ContentSpecificClicktagForm(props) {
  const dispatch = useDispatch();
  const { clicktag, onStopAnimations, onCapturePosthogData } = props;
  const [isClicktagFormOpened, setIsClicktagFormOpened] = useState(true);
  const [isLayersListOpened, setIsLayersListOpened] = useState(false);
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [isEditTitleIconShown, setIsEditTitleIconShown] = useState(false);
  const [isStartOverlaping, setIsStartOverlaping] = useState(false);
  const [isEndOverlaping, setIsEndOverlaping] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { layers } = useSelector((state) => state.template.object_data);
  const [isFadeoutShown, setIsFadeoutShown] = useState(flat(layers).length > 5);
  const clicktagPreviewData = useSelector((state) => state.editorSession.clicktagPreviewData);
  const clicktagErrorMessage = useSelector((state) => state.editorSession.clicktagErrorMessage);
  const selectedFormatId = useSelector((state) => state.editorSession.selectedFormatId);
  const formats = useSelector((state) => state.template.formats);
  const selectedFormat = formats.find((format) => format.id === selectedFormatId);
  const templateClicktags = useSelector((state) => state.template.object_data.clicktags);
  const formatClicktags = selectedFormat?.object_data?.clicktags;
  const clicktags = formatClicktags ?? templateClicktags;
  const duration = useSelector((state) => state.template.object_data.settings.duration);

  const isClicktagePreviewed = (currentClicktagId) =>
    clicktagPreviewData.some((item) => item.clicktagId === currentClicktagId);

  const styledDropdownOpenedRef = useRef();
  const scrollContainerRef = useRef();
  const deleteWarningRef = useRef();
  const configureDropdownTitleRef = useRef();
  const trashIconRef = useRef();

  useClickOutside(
    styledDropdownOpenedRef,
    () => isLayersListOpened && setIsLayersListOpened(false),
    [configureDropdownTitleRef]
  );
  useClickOutside(deleteWarningRef, () => setShowWarning(false), [trashIconRef]);
  const onScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setIsFadeoutShown(false);
      } else {
        setIsFadeoutShown(true);
      }
    }
  };
  const checkUpdatedIntervalOverlaps = (currentClicktagId, updatedData) => {
    let overlaps = false;
    // go through the flattened layers
    flat(layers).forEach((layer) => {
      const layerClicktagData = [];
      const layerClicktags = layer.settings?.clicktag;
      // if the current clicktag is assigned to the layer
      if (
        layerClicktags &&
        layerClicktags.length > 0 &&
        layerClicktags.includes(currentClicktagId)
      ) {
        // go through each of the clicktags
        layerClicktags.forEach((assignedClicktagId) => {
          // get data from the clicktags apart from the current one
          if (assignedClicktagId !== currentClicktagId) {
            const layerClicktag = clicktags.find((item) => item.uuid === assignedClicktagId);
            // get the interval data in array
            layerClicktagData.push({
              start: layerClicktag.start_time,
              end: layerClicktag.stop_time
            });
          }
        });
        // add the new interval to the array
        const intervals = [...layerClicktagData, updatedData];

        intervals.sort((a, b) => a.start - b.start);
        // check if intervals overlap
        for (let i = 0; i < intervals.length - 1; i++) {
          if (parseFloat(intervals[i].end) > parseFloat(intervals[i + 1].start)) {
            // true if they overlap
            overlaps = true;
          }
        }
      }
    });
    return overlaps;
  };
  const handleOverlapingChange = (e, newIntervalDataType) => {
    if (
      checkUpdatedIntervalOverlaps(clicktag.uuid, {
        start: newIntervalDataType === 'startTime' ? e.target.value : clicktag.start_time,
        end: newIntervalDataType === 'stopTime' ? e.target.value : clicktag.stop_time
      }) &&
      e.target.value !== ''
    ) {
      newIntervalDataType === 'startTime' ? setIsStartOverlaping(true) : setIsEndOverlaping(true);
      dispatch(
        setClicktagErrorMessage({
          clicktagId: clicktag.uuid,
          message: 'Assigned clicktag intervals are overlaping!'
        })
      );
    } else {
      dispatch(resetClicktagErrorMessage());
    }
  };
  function resetAnimationsPreview(clicktagData) {
    dispatch(
      updateClicktagPreviewData({
        clicktag: clicktagData,
        zuuviColor: zuuviColors[Math.floor(Math.random() * zuuviColors.length)].color
      })
    );
    onStopAnimations();
  }
  function deleteClicktagFromLayers(currentClicktagId) {
    flat(layers).forEach((layer) => {
      const layerClicktags = layer.settings?.clicktag;
      if (
        layerClicktags &&
        layerClicktags.length > 0 &&
        layerClicktags.includes(currentClicktagId)
      ) {
        dispatch(
          updateContentSpecificClicktagOnLayer(
            layer.uuid,
            currentClicktagId,
            'removeClicktag',
            selectedFormatId
          )
        );
      }
    });
  }
  return (
    <>
      <ToggleRevealSectionButton
        removedBorderTop
        isOpened={isClicktagFormOpened}
        handleOnClick={() => {
          onCapturePosthogData({
            event: 'toggle-open-close-content-specific-clicktag',
            type: 'clicktag',
            menu: 'right',
            menu_type: 'template-settigns',
            submenu: 'clictags',
            is_open: isClicktagFormOpened
          });

          if (!isEditTitleIconShown && !isTitleEditable) {
            setIsClicktagFormOpened(!isClicktagFormOpened);
          }
        }}
        aditionalIcon={
          !isClicktagePreviewed(clicktag.uuid) ? (
            <div
              style={{ paddingRight: 11, display: 'inline', height: 17 }}
              onClick={(e) => {
                e.stopPropagation();
                // add clicktag to the previwed data array
                dispatch(
                  updateClicktagPreviewData({
                    clicktag,
                    zuuviColor: zuuviColors[Math.floor(Math.random() * zuuviColors.length)].color
                  })
                );
              }}>
              <HiddenEyeIcon height={18} width={21} />
            </div>
          ) : (
            <div
              style={{ paddingRight: 11, display: 'inline', height: 14 }}
              onClick={(e) => {
                e.stopPropagation();
                // remove clicktag from previewed array
                dispatch(removePreviewedClicktagFromData(clicktag.uuid));
              }}>
              <EyeIcon fill='#4792e2' height={14} width={21} />
            </div>
          )
        }
        deleteIcon={
          <div
            style={{
              position: 'relative',
              paddingRight: isClicktagFormOpened ? '10px' : '12px',
              height: 14
            }}>
            <div
              ref={trashIconRef}
              onClick={(e) => {
                !showWarning ? setShowWarning(true) : setShowWarning(false);
                e.stopPropagation();
              }}>
              <TrashcanIcon stroke='white' />
            </div>
            {showWarning && (
              <DeleteWarning
                marginTop='4px'
                ref={deleteWarningRef}
                data-cy='option-delete-warning'
                className='format-option-item delete'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  deleteClicktagFromLayers(clicktag.uuid);
                  dispatch(deleteContentSpecificClicktag(clicktag.uuid, selectedFormatId));
                  setShowWarning(false);
                }}>
                <b>DELETE</b>
              </DeleteWarning>
            )}
          </div>
        }
        id={`right-submenu-edit-open-close-multiple-clicktag-form-${clicktag.uuid}`}>
        {!isTitleEditable ? (
          <ClicktagTitleWrapper
            onClick={() => {
              onCapturePosthogData({
                event: 'toggle-open-close-content-specific-clicktag',
                type: 'clicktag',
                menu: 'right',
                menu_type: 'template-settigns',
                submenu: 'clictags',
                is_open: isClicktagFormOpened
              });
              if (!isTitleEditable) {
                setIsClicktagFormOpened(!isClicktagFormOpened);
              }
            }}
            onMouseOver={() => setIsEditTitleIconShown(true)}
            onMouseLeave={() => setIsEditTitleIconShown(false)}>
            <p className='color-white'>{clicktag.title}</p>

            {isEditTitleIconShown && (
              <div
                id='clicktag-edit-name'
                style={{ height: '100%' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsTitleEditable(true);
                }}>
                <EditPenSmall style={{ marginLeft: 5 }} stroke='white' />
              </div>
            )}
          </ClicktagTitleWrapper>
        ) : (
          <ClicktagTitleEditableInput
            id='clicktag-edit-name-input'
            autoFocus
            defaultValue={clicktag.title}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setIsEditTitleIconShown(false);
                setIsTitleEditable(false);
              }
            }}
            onBlur={() => {
              setIsEditTitleIconShown(false);
              setIsTitleEditable(false);
            }}
            onChange={(e) =>
              dispatch(
                updateContentSpecificClicktag(
                  clicktag.uuid,
                  {
                    ...clicktag,
                    title: e.target.value
                  },
                  selectedFormatId
                )
              )
            }
          />
        )}
      </ToggleRevealSectionButton>
      {isClicktagFormOpened && (
        <StyledSectionWrap>
          {clicktagErrorMessage.message && clicktagErrorMessage.clicktagId === clicktag.uuid && (
            <p style={{ fontSize: 12, color: '#DE6E4D', marginBottom: 17 }}>
              {clicktagErrorMessage.message}
            </p>
          )}
          <StyledInputLabelWrapper autoWidth templateSettings>
            <p>Start</p>
            <StyledInputWrapperBig removedBottomMargin>
              <InputNumber
                leftAligned
                id={`clicktag-start-time-${clicktag.uuid}`}
                dataCy='clicktag-start-time'
                withDecimal
                value={clicktag.start_time}
                defaultValue={clicktag.start_time}
                overlaping={isStartOverlaping}
                isMulticlicktag
                max={parseFloat(clicktag.stop_time)}
                min={0}
                overlapingChange={(e) => {
                  handleOverlapingChange(e, 'startTime');
                }}
                onUpdate={(value) => {
                  if (
                    !checkUpdatedIntervalOverlaps(clicktag.uuid, {
                      start: value,
                      end: clicktag.stop_time
                    })
                  ) {
                    const clicktagData = {
                      ...clicktag,
                      start_time: value
                    };
                    dispatch(resetClicktagErrorMessage());
                    setIsStartOverlaping(false);
                    dispatch(
                      updateContentSpecificClicktag(clicktag.uuid, clicktagData, selectedFormatId)
                    );
                    if (isClicktagePreviewed(clicktag.uuid)) {
                      resetAnimationsPreview(clicktagData);
                    }
                  }
                }}
              />
            </StyledInputWrapperBig>
          </StyledInputLabelWrapper>
          <StyledInputLabelWrapper autoWidth templateSettings>
            <p>End</p>
            <StyledInputWrapperBig removedBottomMargin>
              <InputNumber
                leftAligned
                id={`clicktag-end-time-${clicktag.uuid}`}
                dataCy='clicktag-end-time'
                withDecimal
                value={clicktag.stop_time}
                defaultValue={clicktag.stop_time}
                overlaping={isEndOverlaping}
                isMulticlicktag
                max={parseFloat(duration)}
                min={parseFloat(clicktag.start_time)}
                overlapingChange={(e) => handleOverlapingChange(e, 'stopTime')}
                onUpdate={(value) => {
                  if (
                    !checkUpdatedIntervalOverlaps(clicktag.uuid, {
                      start: clicktag.start_time,
                      end: value
                    })
                  ) {
                    const clicktagData = {
                      ...clicktag,
                      stop_time: value
                    };
                    dispatch(resetClicktagErrorMessage());
                    setIsEndOverlaping(false);
                    dispatch(
                      updateContentSpecificClicktag(clicktag.uuid, clicktagData, selectedFormatId)
                    );
                    if (isClicktagePreviewed(clicktag.uuid)) {
                      resetAnimationsPreview(clicktagData);
                    }
                  }
                }}
              />
            </StyledInputWrapperBig>
          </StyledInputLabelWrapper>

          <StyledInputLabelWrapper templateSettings removedBottomMargin>
            <p>Link</p>
            <StyledTextarea
              small
              smallText
              placeholder='Paste URL...'
              value={clicktag.url}
              onChange={(e) => {
                dispatch(
                  updateContentSpecificClicktag(
                    clicktag.uuid,
                    {
                      ...clicktag,
                      url: e.target.value.trim().replace(/[\n\t]+/g, '')
                    },
                    selectedFormatId
                  )
                );
              }}
            />
          </StyledInputLabelWrapper>

          <StyledDropdownClosed
            ref={configureDropdownTitleRef}
            onClick={() => setIsLayersListOpened(!isLayersListOpened)}
            data-cy='assign-clicktag-to-layers'>
            <p>Configure elements</p>
            <SyledArrowDownIconWrapper>
              <AttributeOpenedIcon fill='white' />
            </SyledArrowDownIconWrapper>
          </StyledDropdownClosed>
          {isLayersListOpened && (
            <div style={{ position: 'relative' }}>
              <StyledDropdownOpened
                id={`opened-${clicktag.uuid}`}
                data-cy='assign-clicktag-to-layer-menu-opened'
                ref={styledDropdownOpenedRef}>
                <FadeoutWrapper style={{ position: 'relative' }}>
                  <DropdownLayersWrapper ref={scrollContainerRef} onScroll={onScroll}>
                    {layers.length === 0 ? (
                      <div style={{ marginBottom: 10 }}>No layers to show </div>
                    ) : (
                      layers.map((layer) => (
                        <SelectLayer layer={layer} key={layer.uuid} clicktag={clicktag} />
                      ))
                    )}
                  </DropdownLayersWrapper>
                  {isFadeoutShown && <FadeoutBox />}
                </FadeoutWrapper>
                <SmallButtonWrapper>
                  {' '}
                  <BaseButton
                    id='save-publish-template'
                    text='Done'
                    style={{
                      backgroundColor: '#4792E2',
                      color: 'white',
                      marginTop: 7,
                      width: 204,
                      height: 23,
                      margin: '5px 0px 15px 0px'
                    }}
                    onClick={() => setIsLayersListOpened(false)}
                  />
                </SmallButtonWrapper>
              </StyledDropdownOpened>
            </div>
          )}
        </StyledSectionWrap>
      )}
    </>
  );
}

SelectLayer.defaultProps = { isNested: false, hasMarginBottom: true };
SelectLayer.propTypes = {
  layer: PropTypes.object.isRequired,
  clicktag: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    start_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stop_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  isNested: PropTypes.bool,
  hasMarginBottom: PropTypes.bool
};

ContentSpecificClicktagForm.propTypes = {
  clicktag: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    start_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stop_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  onCapturePosthogData: PropTypes.func.isRequired,
  onStopAnimations: PropTypes.func.isRequired
};
export default ContentSpecificClicktagForm;
