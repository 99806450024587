import { v4 as uuidV4 } from 'uuid';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';

import { addCustomGuide } from '../../redux/slices/template';

function Ruler(props) {
  const { axi, zeroPosition } = props;
  const { selectedFormatId, editorType } = useSelector((state) => state.editorSession);
  const { scale } = useSelector((state) => state.editorSession.studio);
  const userSettings = useSelector((state) => state.userSettings);
  const capturePosthogData = usePosthogCapture();
  const dispatch = useDispatch();

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const getInterval = () => {
    if (scale >= 200) {
      return 25;
    } else if (scale <= 20) {
      return 500;
    } else if (scale <= 40) {
      return 250;
    } else if (scale <= 90) {
      return 100;
    }

    return 50;
  };

  const getSizes = () => {
    let sizes = [];

    const interval = getInterval();
    const scaledinterval = (interval * scale) / 100;
    const startPosition = Math.floor(zeroPosition / scaledinterval);
    const rest = Math.floor(zeroPosition % scaledinterval);
    const edge = ((axi === 'x' ? windowWidth : windowHeight) / scaledinterval) * 2;

    const style = (spacing) => ({
      display: 'flex',
      flexDirection: axi === 'x' ? 'column' : 'row',
      width: axi === 'x' ? `${spacing}px` : '25px',
      height: axi === 'y' ? `${spacing}px` : '25px'
    });

    const getNumberPosition = (number) => {
      const chars = number.toString().length;

      if (chars === 1) {
        return axi === 'x' ? '2px' : '-17px';
      } else if (chars === 2) {
        return axi === 'x' ? '4px' : '-14px';
      } else if (chars === 3) {
        return axi === 'x' ? '6px' : '-14px';
      } else if (chars === 4) {
        return axi === 'x' ? '8px' : '-12px';
      } else if (chars === 5) {
        return axi === 'x' ? '8px' : '-10px';
      }

      return '';
    };

    const textStyle = (number) => ({
      transform: axi === 'y' && 'rotate(-90deg)',
      position: 'relative',
      right: axi === 'x' ? getNumberPosition(number) : '-8px',
      top: axi === 'y' ? getNumberPosition(number) : '2px',
      width: '16px',
      height: '25px'
    });

    for (let i = -startPosition - 1; i < edge; i++) {
      if (i === -startPosition - 1) {
        sizes.push(
          <div key={i} style={style(rest)}>
            {interval * i}
            <div style={{ width: '1px', height: '10px', backgroundColor: '#3E3E3E' }} />
          </div>
        );
      } else {
        sizes.push(
          <div id={`interval-${interval * i}`} key={i} style={style(scaledinterval)}>
            <span style={textStyle(interval * i)}>{interval * i}</span>

            <div
              style={{
                display: 'flex',
                flexDirection: axi === 'x' ? 'row' : 'column',
                marginTop: axi === 'x' ? '5px' : ''
              }}>
              {axi === 'y' && (
                <div style={{ width: '10px', height: '1px', backgroundColor: '#3E3E3E' }} />
              )}
              {axi === 'y' && (
                <div
                  style={{
                    width: '5px',
                    height: '1px',
                    backgroundColor: '#3E3E3E',
                    marginTop: scaledinterval / 2 - 1,
                    marginLeft: '5px'
                  }}
                />
              )}

              {axi === 'x' && (
                <div style={{ width: '1px', height: '10px', backgroundColor: '#3E3E3E' }} />
              )}
              {axi === 'x' && (
                <div
                  style={{
                    width: '1px',
                    height: '5px',
                    backgroundColor: '#3E3E3E',
                    marginLeft: scaledinterval / 2 - 1,
                    marginTop: '5px'
                  }}
                />
              )}
            </div>
          </div>
        );
      }
    }

    return sizes;
  };

  const handleAddCustomGuide = (event) => {
    if (userSettings.ruler && editorType !== 'banner') {
      if (axi === 'x') {
        dispatch(
          addCustomGuide({
            formatId: selectedFormatId,
            customGuide: {
              uuid: uuidV4(),
              x: Math.round(((event.clientX - zeroPosition) / scale) * 100)
            }
          })
        );
      } else if (axi === 'y') {
        dispatch(
          addCustomGuide({
            formatId: selectedFormatId,
            customGuide: {
              uuid: uuidV4(),
              y: Math.round(((event.clientY - zeroPosition) / scale) * 100)
            }
          })
        );
      }
      capturePosthogData({ event: 'add-guideline', type: 'guideline' });
    }
  };

  const style = {
    overflow: 'hidden',
    fontSize: '9px',
    position: 'fixed',
    display: 'flex',
    flexDirection: axi === 'x' ? 'row' : 'column',
    top: axi === 'x' ? '55px' : '0px',
    left: axi === 'y' ? '68px' : '0px',
    height: axi === 'x' ? '25px' : '',
    width: axi === 'x' ? '' : '25px',
    backgroundColor: '#2a2a2a',
    color: '#888888',
    zIndex: axi === 'x' ? '0' : '-1'
  };

  return (
    <div data-cy='ruler' style={style} onClick={handleAddCustomGuide}>
      {getSizes()}
    </div>
  );
}

Ruler.propTypes = {
  axi: PropTypes.string.isRequired,
  zeroPosition: PropTypes.number.isRequired
};

export default Ruler;
