import { v4 as uuidV4 } from 'uuid';
import React, { useRef, useState, useEffect } from 'react';
import {
  GuidedBox as Box,
  Guides,
  GuidesProvider,
  toGuides,
  toGrid,
  Container,
  toSiblings
} from '@zuuvi/zuuvi-mops';

import '@zuuvi/zuuvi-mops/dist/index.css';
import { useSelector, useDispatch } from 'react-redux';
import { PinturaEditorModal, PinturaEditorOverlay } from 'react-pintura';
import {
  // editor
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_resize,
  plugin_resize_locale_en_gb,
  plugin_frame,
  plugin_frame_defaults,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_frame_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb
} from 'pintura';

import { useClickOutside } from '../../assets/utils';
import { recalculateSize, recalculateTextLayer, createColorString, flat } from '../../../helpers';
import useEventListener from '../../../utils/hooks/useEventListener';
import hasDcoTagInText from '../../../utils/hasDcoTagInText';
import { defaultFonts } from '../../assets/defaultFonts';
import TextEditor from './TextEditor';

import 'pintura/pintura.css';
import {
  updateLayerFormat,
  updateLayerImageState,
  updateLayerPosition
} from '../../redux/slices/template';

import {
  rerenderSlateEditor,
  resetRightMenu,
  setIsEditingImage,
  setIsEditingOverlayImage,
  setRightMenu,
  setIsTextEditable,
  resetLeftMenu,
  setScale,
  setSelectedLayers,
  setActiveLayer,
  resetActiveAnimation
} from '../../redux/slices/editorSession';
import ZuuviLoader from '../common/ZuuviLoader';
import useAuthorization from '../../../utils/hooks/useAuthorization';

setPlugins(plugin_crop, plugin_resize, plugin_finetune, plugin_filter);
function Banner({
  bannerOverflowHidden,
  excludedRefs,
  excludedContentEditableRefs,
  canvasIsMovable,
  isEditingTextEnabled,
  onIsTextEditingEnabled,
  userSettings,
  toolbarRef,
  isReadonlyTextEnabled
}) {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const {
    activeLayer,
    selectedFormatId,
    selectedFormat,
    isEditingImage,
    isEditingOverlayImage,
    imageToEdit,
    isTextEditable,
    selectedLayers,
    editorType
  } = useSelector((state) => state.editorSession);

  const template = useSelector((state) => state.template);
  const { timeline, scale, presentLayers, selectedFonts, animation } = useSelector(
    (state) => state.editorSession.studio
  );
  const { rightMenu, leftMenu } = useSelector((state) => state.editorSession.menus);
  const [overlayImageToEdit, setOverlayImageToEdit] = useState(null);
  const { Checkboard } = require('react-color/lib/components/common');
  const updatedScale = parseFloat(scale / 100);

  const bannerRef = useRef();
  const textEditorRef = useRef();
  const childTextEditorRef = useRef();
  const overlayEditorRef = useRef(null);

  const bannerLayersRefs = useRef([]);
  const notEditableLayers = useRef();
  const contentEditableRef = useRef();
  const pinturaOverlayRef = useRef();
  const contentEditableParentRef = useRef();

  const [isNestedTextEditable, setIsNestedTextEditable] = useState(null);
  const [fixedGuides, setFixedGuides] = useState([]);
  const [doubleClickedText, setDoubleClickedText] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const dcoSelection = useSelector((state) => state.dco.dcoSelection);

  // fix for child in group jumping
  const [childStartPosition, setChildStartPosition] = useState({});
  const [childStartResize, setChildStartResize] = useState({});

  const imageEditorDefaults = {
    utils: ['resize', 'crop', 'finetune'],
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter(),
    shapePreprocessor: createDefaultShapePreprocessor(),
    cropAutoCenterImageSelectionTimeout: 0,

    cropImageSelectionCornerStyle: 'hook',
    cropEnableButtonToggleCropLimit: true,
    cropActiveTransformTool: 'zoom',
    cropEnableImageSelection: true,
    cropSelectPresetOptions: [
      [undefined, 'Custom'],
      [
        [selectedFormat?.object_data?.width ?? 0, selectedFormat?.object_data?.height ?? 0],
        `Format (${selectedFormat?.object_data?.width ?? 0}x${
          selectedFormat?.object_data?.height ?? 0
        })`
      ]
    ],

    resizeWillRenderFooter: (footer, env, redraw) => {
      delete footer[1];
      return footer;
    },

    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...markup_editor_defaults,
    ...plugin_frame_defaults,

    finetuneOptions: [
      ['exposure', (locale) => locale.finetuneLabelExposure],
      ['brightness', (locale) => locale.finetuneLabelBrightness],
      ['contrast', (locale) => locale.finetuneLabelContrast],
      ['saturation', (locale) => locale.finetuneLabelSaturation],
      ['temperature', (locale) => locale.finetuneLabelTemperature],
      ['gamma', (locale) => locale.finetuneLabelGamma],
      ['clarity', (locale) => locale.finetuneLabelClarity],
      ['vignette', (locale) => locale.finetuneLabelVignette]
    ],

    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
      ...plugin_resize_locale_en_gb,
      ...plugin_finetune_locale_en_gb,
      ...plugin_filter_locale_en_gb,
      ...plugin_frame_locale_en_gb,
      ...markup_editor_locale_en_gb,
      finetuneLabel: 'Adjust'
    }
  };

  function onUpdateRotation(formatData, layerId) {
    function checkForNullValues(list) {
      for (const [key, value] of Object.entries(list)) {
        if (value === '') {
          list[key] = '0';
          continue;
        }

        if (typeof value === 'object') {
          checkForNullValues(value);
        }
      }
    }

    checkForNullValues(formatData);

    dispatch(updateLayerFormat(selectedFormatId, layerId, formatData));
  }

  const overlayEditorDefaults = (width) => ({
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter(),
    shapePreprocessor: createDefaultShapePreprocessor(),

    willRenderToolbar: (toolbar, env) => {
      if (width < 150) {
        delete toolbar[2];
      } else if (width > 150 && width < 200) {
        toolbar[2][3][0][2].hideLabel = true;
        toolbar[2][3][0][2].icon =
          "<polyline points='20 6 9 17 4 12' fill='none' stroke='currentColor' stroke-width='.125em'></polyline>";
      } else {
        toolbar[2][3][0][2].hideLabel = false;

        toolbar[2][3][0][2].label = 'Done';
        toolbar[2][3][0][2].icon = '';
      }

      return toolbar;
    },

    enableZoomControls: false,
    enableNavigateHistory: false,
    enableToolbar: true,
    enableUtils: false,
    enableButtonRevert: false,
    cropEnableButtonFlipHorizontal: false,
    cropEnableButtonRotateLeft: false,
    cropEnableRotationInput: false,
    cropEnableButtonToggleCropLimit: false,
    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
      statusLabelLoadImage: () => '',
      statusLabelProcessImage: () => ''
    }
  });

  function resetStates() {
    if (activeLayer !== null) dispatch(setActiveLayer(null));
    dispatch(resetActiveAnimation());
    if (rightMenu !== '') dispatch(resetRightMenu());
    if (leftMenu.activeButton !== '') dispatch(resetLeftMenu());
    if (selectedLayers.length > 0) dispatch(setSelectedLayers([]));
  }
  // we need to make layer and child editable when we type to the right Edit menu to resize it
  const activeLayerObj = flat(presentLayers).find((item) => item.uuid === activeLayer);

  const isDcoBeingPreviewed =
    dcoSelection.dcustomSourcePreview ||
    dcoSelection.dynamicSourcePreview ||
    dcoSelection.parameterSourcePreview;

  const recalculateTextLayerSize = () => {
    const { x, y, width, height } = recalculateTextLayer(
      activeLayerObj,
      textEditorRef,
      isEditingTextEnabled,
      isNestedTextEditable,
      isTextEditable
    );

    dispatch(
      updateLayerPosition(selectedFormatId, activeLayer, {
        x: parseInt(x),
        y: parseInt(y),
        width: parseInt(width),
        height: parseInt(height)
      })
    );
  };

  // useEffect(() => {
  //   textEditorRef.current && recalculateTextLayerSize();
  // }, [textEditorRef?.current?.clientWidth, textEditorRef?.current?.clientHeight]);

  useClickOutside(
    bannerRef && bannerRef,
    () => {
      if (!isEditingImage && !isEditingOverlayImage) {
        if (isTextEditable) {
          recalculateTextLayerSize();
          dispatch(setIsTextEditable(null));
          setDoubleClickedText(false);
        }

        if (isEditingTextEnabled !== null) {
          // when interacting with text layer on the right size
          // we need to resize the layer too
          recalculateTextLayerSize();
          onIsTextEditingEnabled(null);
        }
        if (isNestedTextEditable) {
          recalculateTextLayerSize();
          setIsNestedTextEditable(null);
          setDoubleClickedText(false);
        }
        resetStates();
      }
    },
    // don't fire if clicked layer is the editable one
    [
      ...excludedRefs,
      textEditorRef,
      childTextEditorRef,
      toolbarRef,
      contentEditableParentRef,
      notEditableLayers
    ]
  );

  useClickOutside(
    contentEditableParentRef && contentEditableParentRef,
    () => {
      if (!isEditingImage && !isEditingOverlayImage) {
        if (isEditingTextEnabled !== null) {
          recalculateTextLayerSize();
          onIsTextEditingEnabled(null);
        }
        if (isTextEditable !== null || isNestedTextEditable) {
          recalculateTextLayerSize();
          dispatch(setIsTextEditable(null));
          setIsNestedTextEditable(null);
          setDoubleClickedText(false);
          // when we click outside of editable layer it should not deselect if editing is enabled
          // resetStates();
        }
      }
    },
    [...excludedContentEditableRefs, textEditorRef, childTextEditorRef, toolbarRef]
  );

  useClickOutside(
    contentEditableRef && contentEditableRef,
    () => {
      if (!isEditingImage && !isEditingOverlayImage) {
        if (isEditingTextEnabled !== null) {
          recalculateTextLayerSize();
          onIsTextEditingEnabled(null);
        }

        if (isTextEditable !== null || isEditingTextEnabled !== null || isNestedTextEditable) {
          dispatch(setIsTextEditable(null));
          recalculateTextLayerSize();
          setIsNestedTextEditable(null);
          setDoubleClickedText(false);
          resetStates();
        }
      }
    },
    [...excludedContentEditableRefs, textEditorRef, childTextEditorRef, toolbarRef]
  );

  useClickOutside(pinturaOverlayRef && pinturaOverlayRef, () => {
    dispatch(setIsEditingOverlayImage(false));
  });

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      if (isEditingOverlayImage) {
        if (overlayEditorRef !== null && overlayEditorRef.current !== null) {
          const { editor } = overlayEditorRef.current;
          editor.processImage();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', (e) => handleEnter(e));
    return () => window.removeEventListener('keydown', (e) => handleEnter(e));
  }, [isEditingOverlayImage]);

  useEffect(() => {
    async function createGuides() {
      const bannerSize = await selectedFormat.object_data;

      setFixedGuides([
        { uuid: uuidV4(), x: 0 },
        { uuid: uuidV4(), x: bannerSize.width },
        { uuid: uuidV4(), x: bannerSize.width / 2 },
        { uuid: uuidV4(), y: bannerSize.height / 2 },
        { uuid: uuidV4(), y: bannerSize.height },
        { uuid: uuidV4(), y: 0 }
      ]);
    }
    if (
      (selectedFormat?.object_data?.width ?? 0) > 0 &&
      (selectedFormat?.object_data?.height ?? 0) > 0
    )
      createGuides();
  }, [selectedFormat?.object_data?.width, selectedFormat?.object_data?.height]);

  const gridSize = { x: 10, y: 10 };

  const shouldSnap = [
    userSettings.grid && isAuthorized({ featureFlag: 'banner-snap-guides' })
      ? toGrid(gridSize)
      : undefined,
    userSettings.guidelines && isAuthorized({ featureFlag: 'banner-snap-guides' })
      ? toGuides({
          threshold: {
            x: gridSize.x / 2,
            y: gridSize.y / 2
          }
        })
      : undefined
  ];

  function siblingsSnap(layerUuid) {
    function findBaseOffset(layers, offsetX = 0, offsetY = 0) {
      for (const layer of layers) {
        if (layer.uuid === layerUuid) {
          return {
            x: offsetX,
            y: offsetY
          };
        }
        if (layer.type === 'group') {
          const group = findBaseOffset(
            layer.layers,
            offsetX + layer.format.x,
            offsetY + layer.format.y
          );
          if (group) {
            return group;
          }
        }
      }
    }
    const baseOffset = findBaseOffset(presentLayers);
    const snapSiblings = [];
    function setSnapSiblings(layers, offsetX = 0, offsetY = 0) {
      for (const layer of layers) {
        if (layer.type === 'group' && layer.uuid === layerUuid) {
          continue;
        }
        if (layer.type === 'group') {
          setSnapSiblings(layer.layers, offsetX + layer.format.x, offsetY + layer.format.y);
        } else {
          const sibling = {
            ...layer,
            format: {
              ...layer.format
            }
          };
          sibling.format.x += offsetX;
          sibling.format.y += offsetY;
          snapSiblings.push(sibling);
        }
      }
    }
    setSnapSiblings(presentLayers);

    return (
      snapSiblings.length > 1 &&
      toSiblings(
        snapSiblings
          .map((item) => ({
            rotation: { z: item.format.rotation },
            size: {
              width: item.format.width,
              height: item.format.height
            },
            position: {
              x: item.format.x + item.format.width / 2 - baseOffset.x,
              y: item.format.y + item.format.height / 2 - baseOffset.y
            },
            offset: {
              x: baseOffset.x,
              y: baseOffset.y
            },
            uuid: item.uuid
          }))
          .filter((item) => item.uuid !== layerUuid)
      )
    );
  }

  function getLayerFont(fontId) {
    const fontObject =
      selectedFonts.length > 0 && selectedFonts.find((font) => font.uuid === fontId);
    let fontFamily;
    if (fontObject) {
      if (
        fontObject.reference_type === 'web_safe' &&
        !fontObject.value &&
        fontObject.name === 'Arial'
      ) {
        fontFamily = defaultFonts.find((font) => font.name === fontObject.name).value;
      } else
        fontFamily = fontObject.reference_type === 'web_safe' ? fontObject.value : fontObject.name;
    }
    return fontFamily;
  }

  function checkValidURLImage(url) {
    const extensions = ['.jpeg', '.jpg', '.png'];
    return extensions.some((extension) => url.toLowerCase().includes(extension));
  }

  function getLayerImage(backgroundImage, layer) {
    let returnImageData = '';
    let url = '';
    if (layer.type === 'image') {
      if (
        dcoSelection.parameterSourcePreview &&
        layer.dynamic.dynamic_uuid === dcoSelection.parameterSource.id
      ) {
        // IF DCO PARAMETER SOURCE IS PRESENT ON LAYER
        const index = dcoSelection.parameterSource.keys.findIndex(
          (key) => key.name === layer.dynamic.dynamic_key
        );
        if (index >= 0) {
          url = dcoSelection.parameterSource.keys[index].example;
        }
      }

      if (
        dcoSelection.dynamicSourcePreview &&
        layer.dynamic.dynamic_uuid === dcoSelection.dynamicSource.id
      ) {
        // IF DCO DYNAMIC SOURCE IS PRESENT ON LAYER
        url =
          dcoSelection.dynamicSourceFull[dcoSelection.dynamicSourceSelectedRow][
            layer.dynamic.dynamic_key
          ];

        url = encodeURI(url);
      }

      // URL is set if dco is present
      if (url) {
        returnImageData = url;
      } else if (typeof backgroundImage === 'object' && backgroundImage !== null) {
        if (backgroundImage.content_type === 'image/svg+xml') {
          returnImageData = backgroundImage.thumbnail_url;
        } else if (
          'overwrittenBase64' in backgroundImage &&
          selectedFormatId in backgroundImage.overwrittenBase64 &&
          layer.id in backgroundImage.overwrittenBase64[selectedFormatId]
        ) {
          returnImageData = backgroundImage.overwrittenBase64[selectedFormatId][layer.id].image;
        }
      }
      return returnImageData;
    }
  }

  function getLayerOutline(layer, isSelected) {
    if (!isSelected || animation.isPlaying) {
      return 'none';
    }

    if (layer.type === 'image') {
      if (getLayerImage(layer.settings.source, layer) === '') {
        return '1px solid #242424';
      }
    }

    if (layer.type === 'text') {
      if (hasDcoTagInText(layer.settings.text)) {
        return '1px dashed #b049ef';
      }
    }

    return '1px dashed #4792E2';
  }

  // store value in ref to prevent rerender each time
  const updatedScaleRef = useRef();

  useEffect(() => {
    updatedScaleRef.current = updatedScale;
  }, [updatedScale]);

  function mouseScrollZoom(event) {
    // check if command or ctrl is pressed
    if (event.metaKey || event.ctrlKey) {
      if (event.deltaY < 0 && updatedScaleRef.current.toFixed(1) <= 3.9) {
        // update by ref so it doesn't rerender component
        updatedScaleRef.current += 0.1;
        bannerRef.current.style.transform = `scale3d(${updatedScaleRef.current}, ${updatedScaleRef.current}, 1)`;
      }

      if (event.deltaY > 0 && updatedScaleRef.current.toFixed(1) >= 0.2) {
        updatedScaleRef.current -= 0.1;
        bannerRef.current.style.transform = `scale3d(${updatedScaleRef.current}, ${updatedScaleRef.current}, 1)`;
      }
    }
  }
  // set scale state only if user stops holding key to prevent rerendering and laggy zoom
  function mouseStopScrollZoom(event) {
    if (event.key === 'Meta' || event.key === 'Control') {
      dispatch(setScale(parseInt(Math.round(updatedScaleRef.current * 100))));
    }
  }

  useEventListener('wheel', mouseScrollZoom);
  useEventListener('keyup', mouseStopScrollZoom);

  function getFontWeightNum(fontWeightStr) {
    if (fontWeightStr === 'Bold') {
      return 700;
    }
    if (fontWeightStr === 'Regular') {
      return 400;
    }
    return 400;
  }

  // styled handles for mops layer
  const StyledMarker = () => <span className='styledMarkers' />;
  const clicktagPreviewData = useSelector((state) => state.editorSession.clicktagPreviewData);
  // check if any of the clicktags on layer is being previewed
  const checkPreviewContainsClicktag = (layerClicktags) => {
    const filteredClicktags = clicktagPreviewData.filter((item) =>
      layerClicktags.includes(item.clicktagId)
    );
    return filteredClicktags;
  };

  const displayClicktagPreviewData = (filteredClicktags) => {
    let data = { border: 'none', text: '' };
    const titleArr = [];
    if (animation.isPlaying) {
      filteredClicktags.map((item) => {
        if (timeline.time() >= item.start && timeline.time() <= item.stop) {
          data = { text: item.title, border: `1px solid ${item.color}` };
        }
      });
    } else {
      filteredClicktags.map((item) => {
        titleArr.push(item.title);
        data = { text: titleArr, border: `1px solid #4A90E2` };
      });
    }
    return data;
  };

  const getCustomGuides = () =>
    template.formats.find((format) => format.id === selectedFormatId)?.guidelines?.customGuides ??
    [];

  const getGuideRequests = () => {
    let guides;

    if (userSettings.guidelines) {
      guides = [...fixedGuides];

      if (isAuthorized({ featureFlag: 'banner-ruler' }) && userSettings.ruler) {
        guides = guides.concat([...getCustomGuides()]);
      }
    }

    return guides;
  };

  return (
    (selectedFormat?.object_data?.height ?? 0) > 0 &&
    (selectedFormat?.object_data?.width ?? 0) > 0 && (
      <>
        {isEditingImage && imageToEdit && (
          <PinturaEditorModal
            {...imageEditorDefaults}
            src={imageToEdit.settings.source.url}
            imageState={imageToEdit.settings.imageState}
            onHide={() => dispatch(setIsEditingImage(false))}
            onProcess={(e) => {
              dispatch(setIsEditingImage(false));
              dispatch(updateLayerImageState(activeLayer, selectedFormatId, e.imageState, true));
            }}
          />
        )}
        <div
          ref={bannerRef}
          id='banner'
          lang={
            isDcoBeingPreviewed &&
            dcoSelection.dynamicSourceSelectedRow in dcoSelection.dynamicSourceFull &&
            dcoSelection?.dynamicSourceFull[dcoSelection.dynamicSourceSelectedRow]['zvi-lang']
              ? dcoSelection.dynamicSourceFull[dcoSelection.dynamicSourceSelectedRow]['zvi-lang']
              : 'en'
          }
          style={{
            overflow: bannerOverflowHidden,
            ...((isTextEditable || isNestedTextEditable || isEditingTextEnabled) &&
            bannerOverflowHidden === 'visible'
              ? { zoom: updatedScale }
              : (isTextEditable || isNestedTextEditable || isEditingTextEnabled) &&
                bannerOverflowHidden === 'hidden'
              ? { transform: `scale3d(${updatedScale}, ${updatedScale}, 1)` }
              : { transform: `scale3d(${updatedScale}, ${updatedScale}, 1)` }),
            transformOrigin: 'center',
            height: `${selectedFormat.object_data.height}px`,
            width: `${selectedFormat.object_data.width}px`
          }}>
          <Checkboard />
          <GuidesProvider
            key={selectedFormat.id}
            guideRequests={getGuideRequests()}
            containerSize={{
              height: selectedFormat.object_data.height,
              width: selectedFormat.object_data.width
            }}>
            <Container
              grid={
                userSettings.grid &&
                isAuthorized({ featureFlag: 'banner-snap-guides' }) &&
                gridSize.x
              }>
              <Guides
                guideStyle={{
                  color: 'rgb(233, 109, 78)',
                  width: 1
                }}
                guidePosition={{
                  x: 0,
                  y: 0
                }}
                containerSize={{
                  height: selectedFormat.object_data.height,
                  width: selectedFormat.object_data.width
                }}
              />
              {presentLayers &&
                presentLayers.map((layerObject, i) => {
                  const isIgnored = layerObject.settings?.ignored;
                  const isHidden = layerObject.editor_settings?.hidden;
                  const isLocked = layerObject.editor_settings?.locked;

                  const isSelected = layerObject.uuid === activeLayer;
                  const isEditable =
                    (isTextEditable && isTextEditable === layerObject.uuid) ||
                    (isEditingTextEnabled && isEditingTextEnabled === layerObject.uuid);
                  const hasEditableChildren =
                    layerObject.type === 'group' &&
                    layerObject.layers?.length > 0 &&
                    layerObject.layers.some((layer) => layer.uuid === isNestedTextEditable);
                  const isDraggable =
                    !isEditable &&
                    !isNestedTextEditable &&
                    !isLocked &&
                    !canvasIsMovable &&
                    !isEditingImage &&
                    !isEditingOverlayImage &&
                    !animation.isPlaying;
                  const isResizable =
                    !isEditable &&
                    !isNestedTextEditable &&
                    isSelected &&
                    !isLocked &&
                    !canvasIsMovable &&
                    !isEditingImage &&
                    !isEditingOverlayImage &&
                    !animation.isPlaying;
                  const {
                    x,
                    y,
                    z_index,
                    width,
                    height,
                    background_color,
                    background_size,
                    font_color,
                    font_weight,
                    font_style,
                    font_size,
                    rotation,
                    radius,
                    opacity,
                    border,
                    box_shadow,
                    text_shadow,
                    scale,
                    line_height,
                    padding,
                    text_transform,
                    letter_spacing,
                    align_items,
                    justify_content,
                    display,
                    flex_flow,
                    text_alignment
                  } = layerObject.format;
                  // recalculate the size widht border and padding
                  const { calculatedWidth, calculatedHeight } = recalculateSize(
                    width,
                    height,
                    border,
                    padding
                  );
                  return (
                    <React.Fragment key={`overlay-${layerObject.uuid}`}>
                      {layerObject.settings.clicktag &&
                        layerObject.settings.clicktag.length > 0 &&
                        checkPreviewContainsClicktag(layerObject.settings.clicktag).length > 0 && (
                          <div
                            key={`overlay-${layerObject.uuid}`}
                            id={`overlay-${layerObject.uuid}`}
                            style={{
                              position: 'absolute',
                              borderRadius: radius,
                              border: displayClicktagPreviewData(
                                checkPreviewContainsClicktag(layerObject.settings.clicktag)
                              ).border,
                              color: 'white',
                              textShadow: '0px 1px 3px #242424',
                              flexFlow: 'column',
                              left: x,
                              top: y,
                              width: width - 1,
                              height: height - 1,
                              display: 'flex',
                              zIndex: 1000,
                              pointerEvents: 'none',
                              fontSize: 12
                            }}>
                            {Array.isArray(
                              displayClicktagPreviewData(
                                checkPreviewContainsClicktag(layerObject.settings.clicktag)
                              ).text
                            ) ? (
                              displayClicktagPreviewData(
                                checkPreviewContainsClicktag(layerObject.settings.clicktag)
                              ).text.map((title) => (
                                <div style={{ marginTop: 5, marginLeft: 5 }}>{title}</div>
                              ))
                            ) : (
                              <div>
                                {
                                  displayClicktagPreviewData(
                                    checkPreviewContainsClicktag(layerObject.settings.clicktag)
                                  ).text
                                }
                              </div>
                            )}
                          </div>
                        )}
                      <Box
                        onMouseDown={() => {
                          if (activeLayer !== layerObject.uuid) {
                            dispatch(setIsTextEditable(null));
                            setIsNestedTextEditable(null);
                            setDoubleClickedText(false);
                            dispatch(setActiveLayer(layerObject.uuid));
                            dispatch(resetActiveAnimation());
                            if (isEditingTextEnabled !== null) {
                              recalculateTextLayerSize();
                              onIsTextEditingEnabled(null);
                            }
                            if (!rightMenu) {
                              dispatch(setRightMenu('edit'));
                            }
                          }
                        }}
                        isChild={false}
                        isImage={layerObject.type === 'image'}
                        isEditableText={isEditable || hasEditableChildren}
                        ref={(element) =>
                          !bannerLayersRefs.current.includes(element) &&
                          element !== null &&
                          bannerLayersRefs.current.push(element)
                        }
                        id={layerObject.uuid}
                        key={layerObject.uuid}
                        drawBox={false}
                        hideHandles={
                          timeline?.isActive() ||
                          animation.isPlaying ||
                          isHidden ||
                          isIgnored ||
                          !isSelected ||
                          isEditingOverlayImage
                        }
                        marker={StyledMarker}
                        style={{
                          display: isHidden || isIgnored ? 'none' : 'initial',
                          pointerEvents: isLocked || isHidden || isIgnored ? 'none' : 'auto',
                          zIndex: isEditable || hasEditableChildren ? 1000 : z_index
                        }}
                        isDraggable={isDraggable}
                        isResizable={isResizable}
                        isRotatable={isResizable}
                        data-testid='dynamic-element'
                        parentRotation={{ x: 0, y: 0, z: 0 }}
                        onRotateEnd={({ position, rotation, size }) => {
                          onUpdateRotation({ rotation: parseInt(rotation.z) }, layerObject.uuid);
                          // calculate position of the corner instead of middle
                          const newY = position.y - size.height / 2;
                          const newX = position.x - size.width / 2;

                          if (y !== newY || x !== newX) {
                            dispatch(
                              updateLayerPosition(selectedFormatId, layerObject.uuid, {
                                x: parseInt(newX),
                                y: parseInt(newY)
                              })
                            );
                          }
                        }}
                        onResizeEnd={({ position, rotation, size }) => {
                          // get the position of the top left corner (instead of center)
                          const newY = Math.round(position.y - size.height / 2);
                          const newX = Math.round(position.x - size.width / 2);
                          const newWidth = Math.round(size.width);
                          const newHeight = Math.round(size.height);

                          if (
                            y !== newY ||
                            x !== newX ||
                            width !== newWidth ||
                            height !== newHeight
                          ) {
                            dispatch(
                              updateLayerPosition(selectedFormatId, layerObject.uuid, {
                                x: parseInt(newX),
                                y: parseInt(layerObject.type === 'text' ? y : newY),
                                width: parseInt(newWidth),
                                height: parseInt(newHeight)
                              })
                            );
                          }
                          if (layerObject.type === 'image') {
                            if (!('imageState' in layerObject.settings))
                              layerObject.settings.imageState = {};
                            if (!('targetSize' in layerObject.settings.imageState))
                              layerObject.settings.imageState.targetSize = {};

                            dispatch(
                              updateLayerImageState(
                                layerObject.uuid,
                                selectedFormatId,
                                {
                                  ...layerObject.settings.imageState,
                                  targetSize: {
                                    ...layerObject.settings.imageState.targetSize,
                                    height: newHeight,
                                    width: newWidth
                                  }
                                },
                                false
                              )
                            );
                          }
                        }}
                        onDrag={({ position, rotation, size }) => {
                          const newY = Math.round(position.y - size.height / 2);
                          const newX = Math.round(position.x - size.width / 2);

                          if (y !== newY || x !== newX) {
                            setIsDragging(true);
                          }
                        }}
                        onDragEnd={({ position, rotation, size }) => {
                          // provide the position of the top left corner (instead of center)
                          const newY = Math.round(position.y - size.height / 2);
                          const newX = Math.round(position.x - size.width / 2);

                          if (y !== newY || x !== newX) {
                            dispatch(
                              updateLayerPosition(selectedFormatId, layerObject.uuid, {
                                x: parseInt(newX),
                                y: parseInt(newY)
                              })
                            );
                          }
                          setIsDragging(false);
                        }}
                        // provide position of the center
                        position={{
                          x: parseFloat(x) + parseFloat(width) / 2,
                          y: parseFloat(y) + parseFloat(height) / 2
                        }}
                        size={{
                          height: parseInt(height),
                          width: parseInt(width)
                        }}
                        rotation={{
                          x: 0,
                          y: 0,
                          z: parseInt(rotation)
                        }}
                        shouldSnap={
                          isDragging
                            ? [
                                userSettings.guidelines &&
                                  isAuthorized({ featureFlag: 'banner-snap-guides' }) &&
                                  siblingsSnap(layerObject.uuid),
                                ...shouldSnap
                              ].filter(Boolean)
                            : []
                        }
                        scale={updatedScale}
                        outerScale={updatedScale}>
                        {isEditingOverlayImage && layerObject.uuid === overlayImageToEdit.uuid ? (
                          <div
                            ref={pinturaOverlayRef}
                            style={{ height: parseInt(height), width: parseInt(width) }}>
                            <PinturaEditorOverlay
                              ref={overlayEditorRef}
                              src={overlayImageToEdit.settings.source.url}
                              {...overlayEditorDefaults(overlayImageToEdit.format.width)}
                              imageState={overlayImageToEdit.settings.imageState}
                              onHide={() => dispatch(setIsEditingOverlayImage(false))}
                              onProcess={(e) => {
                                dispatch(setIsEditingOverlayImage(false));
                                dispatch(
                                  updateLayerImageState(
                                    layerObject.uuid,
                                    selectedFormatId,
                                    e.imageState,
                                    true
                                  )
                                );
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            id={`layer-${layerObject.uuid}`}
                            data-cy={`${layerObject.type}-layer`}
                            data-cy-group={`${layerObject.type === 'group'}`}
                            key={`layer-${layerObject.uuid}`}
                            ref={
                              isEditable || isSelected
                                ? contentEditableParentRef
                                : notEditableLayers
                            }
                            onDoubleClick={() => {
                              if (
                                layerObject.uuid === activeLayer &&
                                layerObject.type === 'text' &&
                                !isDcoBeingPreviewed
                              ) {
                                dispatch(setIsTextEditable(layerObject.uuid));
                                dispatch(rerenderSlateEditor());
                                setDoubleClickedText(true);
                              }
                              if (
                                layerObject.uuid === activeLayer &&
                                layerObject.type === 'image'
                              ) {
                                if ('url' in layerObject.settings.source) {
                                  if (
                                    layerObject.settings.source.content_type !== 'image/svg+xml'
                                  ) {
                                    setOverlayImageToEdit(layerObject);
                                    dispatch(setIsEditingOverlayImage(true));
                                  }
                                }
                              }
                            }}
                            style={{
                              position: 'fixed',
                              ...(isEditable || hasEditableChildren
                                ? {
                                    transformOrigin: ' 50% 50%',
                                    transform: `rotate(${parseInt(rotation)}deg)`,
                                    minHeight: `${calculatedHeight}px`,
                                    minWidth: `${calculatedWidth}px`,
                                    width:
                                      width -
                                      (border ? parseInt(border.left) : 0) -
                                      (border ? parseInt(border.right) : 0) -
                                      (padding ? parseInt(padding.left) : 0) -
                                      (padding ? parseInt(padding.right) : 0),
                                    height:
                                      height -
                                      (border ? parseInt(border.top) : 0) -
                                      (border ? parseInt(border.bottom) : 0) -
                                      (padding ? parseInt(padding.top) : 0) -
                                      (padding ? parseInt(padding.bottom) : 0)
                                  }
                                : {
                                    width: `calc(100% - ${border ? parseInt(border.left) : 0}px - ${
                                      border ? parseInt(border.right) : 0
                                    }px - ${padding ? parseInt(padding.left) : 0}px - ${
                                      padding ? parseInt(padding.right) : 0
                                    }px)`,
                                    height: `calc(100% - ${border ? parseInt(border.top) : 0}px - ${
                                      border ? parseInt(border.bottom) : 0
                                    }px - ${padding ? parseInt(padding.top) : 0}px - ${
                                      padding ? parseInt(padding.bottom) : 0
                                    }px)`
                                  }),
                              borderStyle: `${
                                border.top > 0 ||
                                border.bottom > 0 ||
                                border.right > 0 ||
                                border.left > 0
                                  ? 'solid'
                                  : 'none'
                              }`,
                              borderWidth: `${border.top}px ${border.right}px ${border.bottom}px ${border.left}px`,
                              borderColor: border.color,
                              boxShadow: ` ${box_shadow.x}px ${box_shadow.y}px ${
                                box_shadow.blur
                              }px ${box_shadow.spread}px ${box_shadow.color}
                              ${box_shadow.inset ? 'inset' : ''}`,
                              borderRadius: radius,
                              ...(layerObject.type === 'text' && {
                                padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`
                              }),
                              opacity: opacity / 100,
                              scale,
                              outline: getLayerOutline(layerObject, isSelected),
                              display: isHidden || isIgnored ? 'none' : 'initial',
                              pointerEvents: isLocked || isHidden || isIgnored ? 'none' : 'auto',
                              // https://www.w3.org/TR/css-backgrounds-3/#background-size
                              // background size needs to come after background-position and be separated with a slash
                              background:
                                layerObject.type === 'image'
                                  ? `url(${getLayerImage(
                                      layerObject.settings.source,
                                      layerObject
                                    )}) no-repeat center / ${background_size}`
                                  : `${createColorString(background_color)} center no-repeat`,
                              visibility: 'visible'
                            }}>
                            {layerObject.type === 'image' &&
                              getLayerImage(layerObject.settings.source, layerObject) === '' && (
                                <div
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}>
                                  <ZuuviLoader size='mini' color='white' />
                                </div>
                              )}
                            {layerObject.type === 'group' &&
                              layerObject.layers?.length > 0 &&
                              layerObject.layers.map((childLayer) => {
                                const {
                                  x,
                                  y,
                                  z_index,
                                  width,
                                  height,
                                  background_color,
                                  background_size,
                                  border,
                                  text_shadow,
                                  box_shadow,
                                  font_color,
                                  font_weight,
                                  font_style,
                                  font_size,
                                  rotation,
                                  radius,
                                  opacity,
                                  scale,
                                  line_height,
                                  padding,
                                  text_transform,
                                  letter_spacing,
                                  align_items,
                                  justify_content,
                                  display,
                                  flex_flow,
                                  text_alignment
                                } = childLayer.format;
                                const { calculatedWidth, calculatedHeight } = recalculateSize(
                                  width,
                                  height,
                                  border,
                                  padding
                                );
                                const isIgnored = childLayer.settings?.ignored;
                                const isHidden = childLayer.editor_settings?.hidden;
                                const isLocked =
                                  childLayer.editor_settings?.locked ||
                                  (editorType === 'banner' && layerObject.editor_settings?.locked);

                                const isChildSelected = childLayer.uuid === activeLayer;
                                const isChildTextEditable =
                                  (isNestedTextEditable &&
                                    isNestedTextEditable === childLayer.uuid) ||
                                  (isEditingTextEnabled &&
                                    isEditingTextEnabled === childLayer.uuid);
                                const isDraggable =
                                  !isChildTextEditable &&
                                  !isLocked &&
                                  !isEditingImage &&
                                  !isEditingOverlayImage &&
                                  !animation.isPlaying;

                                const isResizable =
                                  !isChildTextEditable &&
                                  isChildSelected &&
                                  !isLocked &&
                                  !isEditingImage &&
                                  !isEditingOverlayImage &&
                                  !animation.isPlaying;
                                return (
                                  <React.Fragment key={`overlay-${childLayer.uuid}`}>
                                    {childLayer.settings.clicktag &&
                                      childLayer.settings.clicktag.length > 0 &&
                                      checkPreviewContainsClicktag(childLayer.settings.clicktag)
                                        .length > 0 && (
                                        <div
                                          key={`overlay-${childLayer.uuid}`}
                                          id={`overlay-${childLayer.uuid}`}
                                          style={{
                                            position: 'absolute',
                                            left: x,
                                            top: y,
                                            width: width - 1,
                                            height: height - 1,

                                            border: displayClicktagPreviewData(
                                              checkPreviewContainsClicktag(
                                                childLayer.settings.clicktag
                                              )
                                            ).border,
                                            color: 'white',
                                            textShadow: '0px 1px 3px #242424',
                                            flexFlow: 'column',
                                            fontSize: 12,
                                            zIndex: 1000,
                                            pointerEvents: 'none',
                                            display: 'flex'
                                          }}>
                                          {Array.isArray(
                                            displayClicktagPreviewData(
                                              checkPreviewContainsClicktag(
                                                childLayer.settings.clicktag
                                              )
                                            ).text
                                          ) ? (
                                            displayClicktagPreviewData(
                                              checkPreviewContainsClicktag(
                                                childLayer.settings.clicktag
                                              )
                                            ).text.map((title) => (
                                              <span style={{ marginTop: 5, marginLeft: 5 }}>
                                                {title}
                                              </span>
                                            ))
                                          ) : (
                                            <p>
                                              {
                                                displayClicktagPreviewData(
                                                  checkPreviewContainsClicktag(
                                                    childLayer.settings.clicktag
                                                  )
                                                ).text
                                              }
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    <Box
                                      key={childLayer.uuid}
                                      id={childLayer.uuid}
                                      drawBox={false}
                                      isImage={childLayer.type === 'image'}
                                      isChild={!isChildSelected}
                                      hideHandles={
                                        timeline?.isActive() ||
                                        animation.isPlaying ||
                                        childLayer.editor_settings.hidden ||
                                        childLayer.settings.ignored ||
                                        !isChildSelected
                                      }
                                      marker={StyledMarker}
                                      isDraggable={isDraggable}
                                      isResizable={isResizable}
                                      isRotatable={isResizable}
                                      data-testid='dynamic-element'
                                      onMouseDown={() => {
                                        if (activeLayer !== childLayer.uuid) {
                                          dispatch(setActiveLayer(childLayer.uuid));
                                          if (isTextEditable) {
                                            recalculateTextLayerSize();
                                            dispatch(setIsTextEditable(null));
                                            setDoubleClickedText(false);
                                          }
                                          if (isNestedTextEditable) {
                                            recalculateTextLayerSize();
                                            setIsNestedTextEditable(null);
                                            setDoubleClickedText(false);
                                          }
                                          if (isEditingTextEnabled !== null) {
                                            recalculateTextLayerSize();
                                            onIsTextEditingEnabled(null);
                                            setDoubleClickedText(false);
                                          }
                                          if (!rightMenu) {
                                            dispatch(setRightMenu('edit'));
                                          }
                                        }
                                      }}
                                      style={{
                                        zIndex: isChildTextEditable ? 1000 : z_index,
                                        pointerEvents:
                                          isLocked || isHidden || isIgnored ? 'none' : 'auto'
                                      }}
                                      isEditableText={isChildTextEditable}
                                      parentRotation={{
                                        x: 0,
                                        y: 0,
                                        z: layerObject.format.rotation
                                      }}
                                      onRotateEnd={({ position, rotation, size }) => {
                                        onUpdateRotation(
                                          { rotation: Math.round(rotation.z) },
                                          childLayer.uuid
                                        );

                                        const newY = Math.round(position.y - size.height / 2);
                                        const newX = Math.round(position.x - size.width / 2);
                                        const newWidth = Math.round(size.width);
                                        const newHeight = Math.round(size.height);

                                        if (
                                          y !== newY ||
                                          x !== newX ||
                                          width !== newWidth ||
                                          height !== newHeight
                                        ) {
                                          dispatch(
                                            updateLayerPosition(selectedFormatId, childLayer.uuid, {
                                              x: parseInt(newX),
                                              y: parseInt(newY),
                                              width: parseInt(newWidth),
                                              height: parseInt(newHeight)
                                            })
                                          );
                                        }
                                      }}
                                      onResizeStart={({ position, rotation, size }) => {
                                        setChildStartResize(size);
                                      }}
                                      onResizeEnd={({ position, rotation, size }) => {
                                        const newY = Math.round(position.y - size.height / 2);
                                        const newX = Math.round(position.x - size.width / 2);
                                        const newWidth = Math.round(size.width);
                                        const newHeight = Math.round(size.height);

                                        if (
                                          (y !== newY ||
                                            x !== newX ||
                                            width !== newWidth ||
                                            height !== newHeight) &&
                                          childStartResize !== size
                                        ) {
                                          dispatch(
                                            updateLayerPosition(selectedFormatId, childLayer.uuid, {
                                              x: parseInt(newX),
                                              y: parseInt(childLayer.type === 'text' ? y : newY),
                                              width: parseInt(newWidth),
                                              height: parseInt(newHeight)
                                            })
                                          );
                                        }

                                        if (childLayer.type === 'image') {
                                          if (!('imageState' in childLayer.settings))
                                            childLayer.settings.imageState = {};
                                          if (!('targetSize' in childLayer.settings.imageState))
                                            childLayer.settings.imageState.targetSize = {};

                                          dispatch(
                                            updateLayerImageState(
                                              childLayer.uuid,
                                              selectedFormatId,
                                              {
                                                ...childLayer.settings.imageState,
                                                targetSize: {
                                                  ...childLayer.settings.imageState.targetSize,
                                                  height: parseInt(newHeight),
                                                  width: parseInt(newWidth)
                                                }
                                              },
                                              false
                                            )
                                          );
                                        }
                                      }}
                                      onDragStart={({ position, rotation, size }) => {
                                        setChildStartPosition(position);
                                      }}
                                      onDragEnd={({ position, rotation, size }) => {
                                        // provide the position of the top left corner (instead of center)s
                                        const newY = Math.round(position.y - size.height / 2);
                                        const newX = Math.round(position.x - size.width / 2);

                                        if (
                                          (y !== newY || x !== newX) &&
                                          childStartPosition !== position
                                        ) {
                                          dispatch(
                                            updateLayerPosition(selectedFormatId, childLayer.uuid, {
                                              x: parseInt(newX),
                                              y: parseInt(newY)
                                            })
                                          );
                                        }
                                      }}
                                      position={{
                                        x: x + width / 2,
                                        y: y + height / 2
                                      }}
                                      size={{
                                        height,
                                        width
                                      }}
                                      rotation={{
                                        x: 0,
                                        y: 0,
                                        z: parseInt(rotation)
                                      }}
                                      shouldSnap={[
                                        userSettings.guidelines &&
                                          isAuthorized({ featureFlag: 'banner-snap-guides' }) &&
                                          siblingsSnap(childLayer.uuid),
                                        ...shouldSnap
                                      ].filter(Boolean)}
                                      scale={updatedScale}
                                      outerScale={updatedScale}>
                                      {isEditingOverlayImage &&
                                      childLayer.uuid === overlayImageToEdit.uuid ? (
                                        <div ref={pinturaOverlayRef} style={{ height, width }}>
                                          <PinturaEditorOverlay
                                            ref={overlayEditorRef}
                                            src={overlayImageToEdit.settings.source.url}
                                            {...overlayEditorDefaults(
                                              overlayImageToEdit.format.width
                                            )}
                                            imageState={overlayImageToEdit.settings.imageState}
                                            onHide={() => dispatch(setIsEditingOverlayImage(false))}
                                            onProcess={(e) => {
                                              dispatch(setIsEditingOverlayImage(false));
                                              dispatch(
                                                updateLayerImageState(
                                                  childLayer.uuid,
                                                  selectedFormatId,
                                                  e.imageState,
                                                  true
                                                )
                                              );
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          key={`layer-${childLayer.uuid}`}
                                          id={`layer-${childLayer.uuid}`}
                                          data-cy={`${childLayer.type}-layer`}
                                          data-cy-group={`${childLayer.type === 'group'}`}
                                          onDoubleClick={(e) => {
                                            e.preventDefault();
                                            dispatch(setActiveLayer(childLayer.uuid));
                                            if (childLayer.uuid === activeLayer) {
                                              if (
                                                childLayer.type === 'text' &&
                                                !isDcoBeingPreviewed
                                              ) {
                                                setIsNestedTextEditable(childLayer.uuid);
                                                dispatch(rerenderSlateEditor());
                                                setDoubleClickedText(true);
                                              }
                                              if (childLayer.type === 'image') {
                                                if ('url' in childLayer.settings.source) {
                                                  if (
                                                    childLayer.settings.source.content_type !==
                                                    'image/svg+xml'
                                                  ) {
                                                    setOverlayImageToEdit(childLayer);
                                                    dispatch(setIsEditingOverlayImage(true));
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          ref={
                                            isChildTextEditable || isChildSelected
                                              ? contentEditableParentRef
                                              : notEditableLayers
                                          }
                                          style={{
                                            ...((isIgnored || isHidden) && { display: 'none' }),
                                            position: 'fixed',

                                            ...(isChildTextEditable
                                              ? {
                                                  transform: `rotate(${parseInt(rotation)}deg)`,
                                                  minHeight: `${calculatedHeight}px`,
                                                  minWidth: `${calculatedWidth}px`,
                                                  width:
                                                    width -
                                                    (border ? parseInt(border.left) : 0) -
                                                    (border ? parseInt(border.right) : 0) -
                                                    (padding ? parseInt(padding.left) : 0) -
                                                    (padding ? parseInt(padding.right) : 0),
                                                  height:
                                                    height -
                                                    (border ? parseInt(border.top) : 0) -
                                                    (border ? parseInt(border.bottom) : 0) -
                                                    (padding ? parseInt(padding.top) : 0) -
                                                    (padding ? parseInt(padding.bottom) : 0)
                                                }
                                              : {
                                                  width: `calc(100% - ${
                                                    border ? parseInt(border.left) : 0
                                                  }px - ${
                                                    border ? parseInt(border.right) : 0
                                                  }px - ${
                                                    padding ? parseInt(padding.left) : 0
                                                  }px - ${
                                                    padding ? parseInt(padding.right) : 0
                                                  }px)`,
                                                  height: `calc(100% - ${
                                                    border ? parseInt(border.top) : 0
                                                  }px - ${
                                                    border ? parseInt(border.bottom) : 0
                                                  }px - ${
                                                    padding ? parseInt(padding.top) : 0
                                                  }px - ${
                                                    padding ? parseInt(padding.bottom) : 0
                                                  }px)`
                                                }),
                                            outline:
                                              childLayer.type === 'image' &&
                                              getLayerImage(
                                                childLayer.settings.source,
                                                childLayer
                                              ) === ''
                                                ? '1px solid #242424'
                                                : isChildSelected && !animation.isPlaying
                                                ? '1px dashed #4792E2'
                                                : 'none',
                                            // https://www.w3.org/TR/css-backgrounds-3/#background-size
                                            // background size needs to come after background-position and be separated with a slash
                                            background:
                                              childLayer.type === 'image'
                                                ? `url(${getLayerImage(
                                                    childLayer.settings.source,
                                                    childLayer
                                                  )}) no-repeat center / ${background_size}`
                                                : `${createColorString(
                                                    background_color
                                                  )} center no-repeat`,
                                            borderStyle: `${
                                              border.top > 0 ||
                                              border.bottom > 0 ||
                                              border.right > 0 ||
                                              border.left > 0
                                                ? 'solid'
                                                : 'none'
                                            }`,
                                            borderWidth: `${border.top}px ${border.right}px ${border.bottom}px ${border.left}px`,
                                            borderColor: border.color,
                                            boxShadow: ` ${box_shadow.x}px ${box_shadow.y}px ${
                                              box_shadow.blur
                                            }px ${box_shadow.spread}px ${box_shadow.color} ${
                                              box_shadow.inset ? 'inset' : ''
                                            }`,
                                            borderRadius: radius,
                                            opacity: opacity / 100,
                                            scale,
                                            pointerEvents:
                                              isLocked || isHidden || isIgnored ? 'none' : 'auto',
                                            ...(childLayer.type === 'text' && {
                                              padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`,
                                              visibility: 'inherit'
                                            })
                                          }}>
                                          {childLayer.type === 'image' &&
                                            getLayerImage(
                                              childLayer.settings.source,
                                              childLayer
                                            ) === '' && (
                                              <div
                                                style={{
                                                  width: '100%',
                                                  height: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center'
                                                }}>
                                                <ZuuviLoader size='mini' color='white' />
                                              </div>
                                            )}
                                          {childLayer.type === 'text' && (
                                            <TextEditor
                                              bannerOverflowHidden={bannerOverflowHidden}
                                              outerScale={updatedScale}
                                              textEditorRef={
                                                isChildTextEditable ? textEditorRef : undefined
                                              }
                                              toolbarRef={toolbarRef}
                                              readOnly={
                                                !isChildTextEditable || isReadonlyTextEnabled
                                              }
                                              toolbarEnabled
                                              layerObject={childLayer}
                                              style={{
                                                width: '100%',
                                                fontFamily: getLayerFont(childLayer.settings.font),
                                                fontWeight: getFontWeightNum(font_weight),
                                                fontStyle: font_style,
                                                pointerEvents:
                                                  isLocked || isHidden || isIgnored
                                                    ? 'none'
                                                    : 'auto',
                                                outline: 'none',
                                                color: font_color,
                                                fontSize: font_size,
                                                textShadow: ` ${text_shadow.x}px ${text_shadow.y}px ${text_shadow.blur}px  ${text_shadow.color} `,
                                                lineHeight:
                                                  line_height === 'normal' ||
                                                  line_height === 'inherit'
                                                    ? 'inherit'
                                                    : `${line_height}px`,
                                                textTransform: text_transform || 'none',
                                                letterSpacing:
                                                  letter_spacing !== undefined
                                                    ? `${letter_spacing}px`
                                                    : '0px',
                                                alignItems: align_items,
                                                justifyContent: justify_content,
                                                height: '100%',
                                                display,
                                                flexFlow: flex_flow,
                                                textAlign: text_alignment,
                                                hyphens:
                                                  hasDcoTagInText(childLayer.settings.text) &&
                                                  isDcoBeingPreviewed
                                                    ? 'auto'
                                                    : 'none'
                                              }}
                                              doubleClickedText={doubleClickedText}
                                            />
                                          )}
                                          {childLayer.type === 'video' && (
                                            <video
                                              id={`video-layer-${childLayer.uuid}`}
                                              key={childLayer.uuid}
                                              muted
                                              autoPlay={false}
                                              controls={false}
                                              preload='auto'
                                              disabled={isLocked}
                                              style={{
                                                position: 'absolute',
                                                height: '100%',
                                                width: '100%',
                                                pointerEvents:
                                                  isLocked || isHidden || isIgnored
                                                    ? 'none'
                                                    : 'auto',
                                                display:
                                                  childLayer.editor_settings?.hidden ||
                                                  childLayer.settings.ignored
                                                    ? 'none'
                                                    : 'initial',
                                                outline:
                                                  isNestedTextEditable &&
                                                  isSelected &&
                                                  !animation.isPlaying
                                                    ? '1px dashed #4792E2'
                                                    : 'none',
                                                backgroundImage: `url(${childLayer.settings.source.thumbnail_base64})`,
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center'
                                              }}
                                              className={childLayer.uuid}
                                              src={childLayer.settings.source.url}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </Box>
                                  </React.Fragment>
                                );
                              })}
                            {layerObject.type === 'text' && (
                              <TextEditor
                                outerScale={updatedScale}
                                bannerOverflowHidden={bannerOverflowHidden}
                                textEditorRef={isEditable ? textEditorRef : undefined}
                                toolbarRef={toolbarRef}
                                readOnly={!isEditable || isReadonlyTextEnabled}
                                toolbarEnabled
                                layerObject={layerObject}
                                style={{
                                  width: '100%',
                                  fontFamily: getLayerFont(layerObject.settings.font),
                                  fontWeight: getFontWeightNum(font_weight),
                                  fontStyle: font_style,
                                  pointerEvents: (isLocked || isHidden || isIgnored) && 'none',
                                  outline: 'none',
                                  color: font_color,
                                  fontSize: font_size,
                                  textShadow: ` ${text_shadow.x}px ${text_shadow.y}px ${text_shadow.blur}px  ${text_shadow.color} `,
                                  lineHeight:
                                    line_height === 'normal' || line_height === 'inherit'
                                      ? 'inherit'
                                      : `${line_height}px`,
                                  textTransform: text_transform || 'none',
                                  letterSpacing:
                                    letter_spacing !== undefined ? `${letter_spacing}px` : '0px',
                                  alignItems: align_items,
                                  justifyContent: justify_content,
                                  height: '100%',
                                  display,
                                  flexFlow: flex_flow,
                                  textAlign: text_alignment,
                                  hyphens:
                                    hasDcoTagInText(layerObject.settings.text) &&
                                    isDcoBeingPreviewed
                                      ? 'auto'
                                      : 'none'
                                }}
                                doubleClickedText={doubleClickedText}
                              />
                            )}
                            {layerObject.type === 'video' && (
                              <video
                                id={`video-layer-${layerObject.uuid}`}
                                key={layerObject.uuid}
                                muted
                                autoPlay={false}
                                controls={false}
                                preload='auto'
                                disabled={layerObject.editor_settings?.locked}
                                style={{
                                  position: 'absolute',
                                  height: '100%',
                                  width: '100%',
                                  pointerEvents:
                                    isLocked || isHidden || isIgnored ? 'none' : 'auto',
                                  display:
                                    layerObject.editor_settings?.hidden ||
                                    layerObject.settings.ignored
                                      ? 'none'
                                      : 'initial',
                                  outline:
                                    isEditable && isSelected && !animation.isPlaying
                                      ? '1px dashed #4792E2'
                                      : 'none',
                                  backgroundImage: `url(${layerObject.settings.source.thumbnail_base64})`,
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center'
                                }}
                                src={layerObject.settings.source.url}
                                className={layerObject.uuid}
                              />
                            )}
                          </div>
                        )}
                      </Box>
                    </React.Fragment>
                  );
                })}
            </Container>
          </GuidesProvider>
        </div>
      </>
    )
  );
}

export default Banner;
