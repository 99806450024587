import { useEffect, useCallback, useReducer } from 'react';

/////////////////////////////////////////////////////////////////////////////////////
//                                                                                 //
//                         FULL LIST OF AVAILABLE KEYS HERE                        //
//  https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values  //
//                                                                                 //
/////////////////////////////////////////////////////////////////////////////////////
// HOW TO USE:
//  -  useKeyboardShortcut(keysArray, callback, { options })
//  -  keysArray: should be an array of KeyboardEvent.key strings. A full list of strings can be seen here
//  -  callback: should be a function that is called once the keys have been pressed.
//  -  Options: You can pass other options to with they options array:
//      -  overrideSystem: overrides the default browser behavior for that specific keyboard shortcut
//      -  metaKeyPressed: Requires the user to have CMD or CTRL pressed
//      -  shiftKeyPressed: Requires the user to have SHIFT pressed
//      -  overrideContentEditable: Override contentEditable
/////////////////////////////////////////////////////////////////////////////////////

// Prevent
function disabledEventPropagation(e) {
  if (e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (window.event) {
      window.event.cancelBubble = true;
    }
  }
}

const keysReducer = (state, action) => {
  switch (action.type) {
    case 'set-key-down':
      const keydownState = { ...state, [action.key]: true };
      return keydownState;
    case 'set-key-up':
      const keyUpState = { ...state, [action.key]: false };
      return keyUpState;
    case 'reset-keys':
      const resetState = { ...action.data };
      return resetState;
    default:
      return state;
  }
};

export const UseKeyboardShortcut = (shortcutKeys, callback, options) => {
  if (!Array.isArray(shortcutKeys))
    throw new Error(
      'The first parameter to `useKeyboardShortcut` must be an ordered array of `KeyboardEvent.key` strings.'
    );

  if (!shortcutKeys.length)
    throw new Error(
      'The first parameter to `useKeyboardShortcut` must contain atleast one `KeyboardEvent.key` string.'
    );

  if (!callback || typeof callback !== 'function')
    throw new Error(
      'The second parameter to `useKeyboardShortcut` must be a function that will be envoked when the keys are pressed.'
    );

  const {
    overrideSystem = false,
    metaKeyPressed = false,
    shiftKeyPressed = false,
    overrideContentEditable = false,
    overrideInput = false
  } = options || {};

  const initalKeyMapping = shortcutKeys.reduce((currentKeys, key) => {
    currentKeys[key.toLowerCase()] = false;
    return currentKeys;
  }, {});

  const [keys, setKeys] = useReducer(keysReducer, initalKeyMapping);

  const keydownListener = useCallback(
    (assignedKey) => (keydownEvent) => {
      // check for colorPicker 'rc-editable-input' to prevent trigger on autocomplete
      if (
        keydownEvent.target.tagName === 'INPUT' &&
        keydownEvent.target.id.includes('rc-editable-input')
      )
        return;

      const loweredKey = assignedKey.toLowerCase();
      if (keydownEvent.key && loweredKey !== keydownEvent.key.toLowerCase()) return;
      if (keys[loweredKey] === undefined) return;

      if (overrideContentEditable === false && keydownEvent.target.isContentEditable === true)
        return;
      if (
        overrideContentEditable === false &&
        overrideInput === false &&
        keydownEvent.target.nodeName === 'INPUT'
      )
        return;
      if (
        overrideContentEditable === false &&
        overrideInput === false &&
        keydownEvent.target.nodeName === 'TEXTAREA'
      )
        return;

      if (metaKeyPressed === true && (keydownEvent.metaKey || keydownEvent.ctrlKey) === false)
        return;
      if (metaKeyPressed === false && (keydownEvent.metaKey || keydownEvent.ctrlKey) === true)
        return;

      if (shiftKeyPressed === true && keydownEvent.shiftKey === false) return;
      if (shiftKeyPressed === false && keydownEvent.shiftKey === true) return;

      if (overrideSystem) {
        keydownEvent.preventDefault();
        disabledEventPropagation(keydownEvent);
      }

      setKeys({ type: 'set-key-down', key: loweredKey });
      return false;
    },
    [keys, overrideSystem]
  );

  const keyupListener = useCallback(
    (assignedKey) => (keyupEvent) => {
      const raisedKey = assignedKey.toLowerCase();

      if (keyupEvent.key && keyupEvent.key.toLowerCase() !== raisedKey) return;
      if (keys[raisedKey] === undefined) return;

      if (overrideContentEditable === false && keyupEvent.target.isContentEditable === true) return;
      if (overrideContentEditable === false && keyupEvent.target.nodeName === 'INPUT') return;
      if (overrideContentEditable === false && keyupEvent.target.nodeName === 'TEXTAREA') return;

      if (metaKeyPressed === true && (keyupEvent.metaKey || keyupEvent.ctrlKey) === false) return;
      if (metaKeyPressed === false && (keyupEvent.metaKey || keyupEvent.ctrlKey) === true) return;

      if (shiftKeyPressed === true && keyupEvent.shiftKey === false) return;
      if (shiftKeyPressed === false && keyupEvent.shiftKey === true) return;

      if (overrideSystem) {
        keyupEvent.preventDefault();
        disabledEventPropagation(keyupEvent);
      }

      setKeys({ type: 'set-key-up', key: raisedKey });
      return false;
    },
    [keys, overrideSystem]
  );

  useEffect(() => {
    if (!Object.values(keys).filter((value) => !value).length) {
      callback(keys);
      setKeys({ type: 'reset-keys', data: initalKeyMapping });
    } else {
      setKeys({ type: null });
    }
    return () => {};
  }, [callback, keys]);

  // Create key down listeners
  useEffect(() => {
    shortcutKeys.forEach((k) => window.addEventListener('keydown', keydownListener(k)));
    return () =>
      shortcutKeys.forEach((k) => window.removeEventListener('keydown', keydownListener(k)));
  }, []);

  // Create key up listeners
  useEffect(() => {
    shortcutKeys.forEach((k) => window.addEventListener('keyup', keyupListener(k)));
    return () => shortcutKeys.forEach((k) => window.removeEventListener('keyup', keyupListener(k)));
  }, []);
};
