import { takeEvery, delay, call } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as api from '../../api';
import { newVersionToast, statusMessageToast } from '../slices/toasts';

export function* showNewVersionToast() {
  yield delay(500);
  yield toast.loading(
    <div>
      <p style={{ marginBottom: '5px' }}>We have released a new version.</p>
      <p style={{ marginTop: '5px' }}>
        Please save your current work and then refresh your browser, to get the newest features.
      </p>
    </div>,
    {
      duration: Infinity,
      style: {
        maxWidth: '400px'
      },
      icon: '🎉',
      id: 'new-version'
    }
  );
}

export function* showStatusMessageToast() {
  yield delay(500);

  let textMessage = 'Status message!';

  try {
    const { data } = yield call(api.lambdaService.getStatusMessage);
    if (data) {
      textMessage = data;
    }
  } catch (e) {
    //TODO handle error
    console.error(e);
  }

  yield toast.loading(
    <div
      onClick={() => {
        // toast.dismiss('status-message');
      }}>
      <p style={{ marginBottom: '5px' }}>{textMessage}</p>
      <div style={{ textAlign: 'center' }}>
        <button
          onClick={() => {
            toast.dismiss('status-message');
          }}
          style={{
            marginRight: '20px',
            color: '#fff',
            width: '46px',
            height: '35px',
            // position: 'relative',
            borderRadius: '4px',
            fontSize: '14px',
            lineHeight: '17px',
            textAlign: 'center',
            cursor: 'pointer'
          }}>
          <svg
            className=''
            width='10'
            height='10'
            viewBox='0 0 10 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M9 9.00072L1.00036 1M9 1.00073L1 9.00072L9 1.00073Z'
              stroke='#888888'
              strokeWidth='1.5'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
      </div>
    </div>,
    {
      duration: Infinity,
      style: {
        maxWidth: '400px',
        border: '2px solid',
        borderColor: '#F5EC00'
      },
      icon: '❕',
      id: 'status-message'
    }
  );
}

function* watchToasts() {
  yield takeEvery(newVersionToast, showNewVersionToast);
  yield takeEvery(statusMessageToast, showStatusMessageToast);
}

export default watchToasts;
