/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { select, call, put, fork, takeLatest } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import {
  getCreativeSet,
  saveCreativeSet,
  publishCreativeSet,
  setTemplate,
  resetUndoHistory
} from '../slices/template';
import { setCurrentAccountId } from '../slices/auth';
import { setError } from '../slices/error';
import * as api from '../../api';
import { selectFormat, setCampaignId } from '../slices/editorSession';
import { loadUsedAssets, loadAssets } from './assetsHelper';
import { fetchDcoFromTemplate } from '../slices/dco';
// selectors need to be exported for testing
export const selectTemplate = (state) => state.template;
export const selectSelectedFormatId = (state) => state.editorSession.selectedFormatId;

/**
 * Get ad
 */
export function* handleGetCreativeSet(action) {
  const toastId = yield toast.loading('Loading creatives ...', { id: 'load-campaign' });
  try {
    const { data } = yield call(api.creativeSetService.getCreativeSet, action.payload);
    yield toast.dismiss(toastId);
    yield fork(handleGetCreativeSetSucceeded, data);
  } catch (e) {
    yield toast.error('Error loading banner.', {
      id: toastId
    });
    yield put(setError(e));
  }
}

export function* handleGetCreativeSetSucceeded(data) {
  data.object_data = data.objectData;
  data.formats = [];

  data.creatives.forEach((creative) => {
    creative.object_data = creative.objectData;
    data.formats.push(creative);
  });

  yield put(setCurrentAccountId(data.accountId));
  yield put(setCampaignId(data.id));
  yield put(setTemplate(data));
  yield put(selectFormat(data.formats[0]));

  yield put(resetUndoHistory());

  yield put(fetchDcoFromTemplate());

  // Load thumbnail images
  yield call(loadAssets);

  yield call(loadUsedAssets, data);
}

/**
 * Save Creative Set
 */
export function* handleSaveCreativeSet(action) {
  const toastId = yield toast.loading('Saving set ...', { id: 'save-ad' });

  const { creativeSetId } = action.payload;
  const template = yield select(selectTemplate);

  try {
    const requestData = {
      ...template,
      objectData: template.object_data,
      formats: []
    };

    // Remove useless data
    delete requestData._history;

    template.formats.forEach((format) => {
      requestData.formats.push({
        ...format,
        objectData: format.object_data
      });
    });

    const request = yield call(api.creativeSetService.saveCreativeSet, creativeSetId, requestData);

    if (request.status === 200 || request.status === 201) {
      yield fork(handleSaveCreativeSetSucceeded, toastId, action.payload.callback);
    } else {
      yield fork(handleSaveCreativeSetFailed, toastId, request, action.payload.failCallback);
    }
  } catch (e) {
    yield fork(handleSaveCreativeSetFailed, toastId, e, action.payload.failCallback);
  }
}

export function* handleSaveCreativeSetSucceeded(toastId, callback) {
  yield toast.success('Creatives saved.', {
    id: toastId
  });
  if (callback) yield callback();
}

export function* handleSaveCreativeSetFailed(toastId, error, callback) {
  yield toast.error('Error saving creatives.', {
    id: toastId
  });
  yield put(setError(error));
  if (callback) yield callback();
}

/**
 * Publish Creative Set
 */

export function* handlePublishCreativeSet(action) {
  const toastId = yield toast.loading('Publishing Creative Set ...', { id: 'publish-set' });

  const { creativeSetId } = action.payload;
  const template = yield select(selectTemplate);

  try {
    const requestData = {
      ...template,
      objectData: template.object_data,
      formats: []
    };

    // Remove useless data
    delete requestData._history;

    template.formats.forEach((format) => {
      requestData.formats.push({
        ...format,
        objectData: format.object_data
      });
    });

    const request = yield call(
      api.creativeSetService.publishCreativeSet,
      creativeSetId,
      requestData
    );

    if (request.status === 200 || request.status === 201) {
      yield fork(handlePublishCreativeSetSucceeded, toastId, action.payload.callback);
    } else {
      yield fork(handlePublishCreativeSetFailed, toastId, request, action.payload.failCallback);
    }
  } catch (e) {
    yield fork(handlePublishCreativeSetFailed, toastId, e, action.payload.failCallback);
  }
}
export function* handlePublishCreativeSetSucceeded(toastId, callback) {
  yield toast.success('Creative Set published.', {
    id: toastId
  });
  if (callback) yield callback();
}

export function* handlePublishCreativeSetFailed(toastId, error, callback) {
  yield toast.error('Error publishing Creative Set', {
    id: toastId
  });
  yield put(setError(error));
  if (callback) yield callback();
}

function* watchCreativeSet() {
  yield takeLatest(getCreativeSet, handleGetCreativeSet);
  yield takeLatest(saveCreativeSet, handleSaveCreativeSet);
  yield takeLatest(publishCreativeSet, handlePublishCreativeSet);
}

export default watchCreativeSet;
