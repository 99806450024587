import { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '../../assets/icons';
import BaseButton from './BaseButton';
import { useClickOutside } from '../../assets/utils';
import ModalOverlayPortal from './ModalOverlayPortal';
import {
  setEditDatasourceModalOpened,
  setEditingDatasource,
  updateDynamicSource
} from '../../redux/slices/dco';
import { UseKeyboardShortcut } from '../../../HotkeyHandler';

const buttonStyle = {
  backgroundColor: '#b049ef',
  height: '30px',
  width: '100%',
  borderRadius: 4
};

const StyledWrapper = styled.div`
  border: 1px solid #747474;
  background: #313131;
  color: white;
  padding: 20px;
  margin: 220px auto 0;
  border-radius: 4px;
  z-index: 10000;
  width: 300px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
`;

const StyledCloseIconWrapper = styled.div`
  position: relative;
  right: 0px;
  top: 0px;
  text-align: right;
  height: 0px;
  cursor: pointer;
  padding-right: ${(props) => (props.meetingsBookingFinished ? `220px` : `0px`)};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
`;

const TextInput = styled.input`
  width: 100%;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #747474;
  background-color: #313131;
  color: white;
  outline: none;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 300;
  box-sizing: border-box;
`;

const HeadlineText = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const LabelText = styled.span`
  font-size: 12px;
`;

const EditDatasourceModal = (props) => {
  const { featureRef, id, modalId } = props;
  const editingDatasource = useSelector((state) => state.dco.editingDatasource);

  const dispatch = useDispatch();
  const modalRef = useRef();

  const [datasource, setDatasource] = useState(editingDatasource);

  function closeModal() {
    dispatch(setEditDatasourceModalOpened(false));
    dispatch(setEditingDatasource({}));
  }

  function updateSource() {
    dispatch(updateDynamicSource(datasource));
    closeModal();
  }

  useClickOutside(
    modalRef,
    () => {
      closeModal();
    },
    []
  );

  UseKeyboardShortcut(
    ['Enter'],
    () => {
      updateSource();
    },
    { overrideSystem: true, overrideInput: true }
  );

  return (
    <ModalOverlayPortal textAlign='center' id={id} keyboard={false}>
      <div ref={featureRef} id={modalId}>
        <StyledWrapper ref={modalRef}>
          <StyledCloseIconWrapper
            hubspotIframeOpened={false}
            meetingsBookingFinished={false}
            onClick={() => {
              closeModal();
            }}>
            <CloseIcon stroke='white' width={13} height={13} />
          </StyledCloseIconWrapper>
          <ContentWrapper>
            <HeadlineText>Edit datasource</HeadlineText>
            <InputWrapper>
              <LabelText>Name</LabelText>
              <TextInput
                id='edit-datasource-name-input'
                input='text'
                value={datasource.name}
                onChange={(e) => {
                  setDatasource({ ...datasource, name: e.target.value });
                }}
              />
            </InputWrapper>

            <BaseButton
              text='Save datasource'
              id='save-edited-datasource-buttonw'
              style={buttonStyle}
              onClick={() => updateSource()}
            />
          </ContentWrapper>
        </StyledWrapper>
      </div>
    </ModalOverlayPortal>
  );
};
EditDatasourceModal.defaultProps = {};

EditDatasourceModal.propTypes = {
  featureRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  id: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired
};
export default EditDatasourceModal;
