import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToggleButton from '../ToggleButton';
import { SettingsIcon, MenuArrowDown, MenuArrowRight } from '../../assets/icons';
import { getUserSettings, updateUserSettings } from '../../redux/slices/userSettings';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import useAuthorization from '../../../utils/hooks/useAuthorization';

function Zoom() {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const userSettings = useSelector((state) => state.userSettings);
  const user = useSelector((state) => state.auth.user);

  const [userSettingsMenu, setUserSettingsMenu] = useState({
    isOpened: false,
    backgroundIsOpened: false
  });
  const userSettingsButtonRef = useRef();
  const userSettingsRef = useRef();

  const userId = user.id;

  const capturePosthogData = usePosthogCapture();

  // fetch user settings
  useEffect(() => {
    dispatch(getUserSettings(userId));
  }, [userId]);

  function updateState(value) {
    setUserSettingsMenu({ ...userSettingsMenu, ...value });
  }

  const userSettingsMenuData = [
    {
      mainMenuItem: 'Overflow',
      stateAssign: updateState,
      initialData: userSettings.overflow !== 'visible',
      updateData: (data) => {
        capturePosthogData({
          event: 'update-settings',
          settings_type: 'overflow',
          type: 'user-settings',
          change_from: userSettings.overflow,
          change_to: data ? 'hidden' : 'visible'
        });
        data !== userSettings.overflow &&
          dispatch(
            updateUserSettings({
              userId,
              settingsType: 'overflow',
              changeFrom: userSettings.overflow,
              changeTo: data ? 'hidden' : 'visible'
            })
          );
      },
      isShown: true
    },
    {
      mainMenuItem: 'Grid',
      stateAssign: updateState,
      initialData: !userSettings.grid,
      updateData: (data) => {
        capturePosthogData({
          event: 'update-settings',
          settings_type: 'grid',
          type: 'user-settings',
          change_from: userSettings.grid,
          change_to: !data
        });
        dispatch(
          updateUserSettings({
            userId,
            settingsType: 'grid',
            changeFrom: userSettings.grid,
            changeTo: !data
          })
        );
      },
      isShown: isAuthorized({ featureFlag: 'banner-snap-guides' })
    },
    {
      mainMenuItem: 'Snapping',
      stateAssign: updateState,
      initialData: !userSettings.guidelines,
      updateData: (data) => {
        capturePosthogData({
          event: 'update-settings',
          settings_type: 'guidelines',
          type: 'user-settings',
          change_from: userSettings.guidelines,
          change_to: !data
        });
        dispatch(
          updateUserSettings({
            userId,
            settingsType: 'guidelines',
            changeFrom: userSettings.guidelines,
            changeTo: !data
          })
        );
      },
      isShown: isAuthorized({ featureFlag: 'banner-snap-guides' })
    },
    {
      mainMenuItem: 'Ruler',
      stateAssign: updateState,
      initialData: !userSettings.ruler,
      updateData: (data) => {
        capturePosthogData({
          event: 'update-settings',
          settings_type: 'ruler',
          type: 'user-settings',
          change_from: userSettings.ruler,
          change_to: !data
        });
        dispatch(
          updateUserSettings({
            userId,
            settingsType: 'ruler',
            changeFrom: userSettings.ruler,
            changeTo: !data
          })
        );
      },
      isShown: isAuthorized({ featureFlag: 'banner-ruler' })
    },
    {
      mainMenuItem: 'Background',
      menuItemOpened: userSettingsMenu.backgroundIsOpened,
      stateAssign: updateState,
      data: {
        backgroundIsOpened: !userSettingsMenu.backgroundIsOpened
      },
      type: 'userSettingsMenu',
      submenu: [
        {
          submenuItem: 'White',

          data: '#FFFFFF',
          type: 'background'
        },
        {
          submenuItem: 'Light Gray',

          data: '#BBBBBB',
          type: 'background'
        },
        {
          submenuItem: 'Medium Gray',

          data: '#808080',
          type: 'background'
        },
        {
          submenuItem: 'Dark Gray',

          data: '#4E4E4E',
          type: 'background'
        },
        {
          submenuItem: 'Darker Gray',

          data: '#272727',
          type: 'background'
        },
        {
          submenuItem: 'Black',

          data: '#000000',
          type: 'background'
        }
      ]
    }
  ];

  return (
    <div id='userSettingsContainer'>
      <button
        type='button'
        ref={userSettingsButtonRef}
        onClick={() => {
          capturePosthogData({
            event: 'open-close-user-settings-menu',
            type: 'user-settings',
            is_open: !userSettingsMenu.isOpened
          });
          setUserSettingsMenu({
            backgroundIsOpened: false,
            isOpened: !userSettingsMenu.isOpened
          });
        }}
        className='top-menu-right-section-box margin-right-pointer settings-button'
        style={{ padding: '0px 10px' }}>
        {' '}
        <SettingsIcon
          className='margin-right-small-pointer icon-hover'
          fill={userSettingsMenu.isOpened ? '#4792E2' : '#C0C0C0'}
        />
        <p className='text-medium'>Settings</p>
        <MenuArrowDown
          width={8}
          fill={userSettingsMenu.isOpened ? '#4792E2' : '#8B8B8B'}
          className='button-margin-left-small-pointer'
        />
      </button>

      <ul
        className='settings-menu'
        ref={userSettingsRef}
        style={{ display: userSettingsMenu.isOpened ? 'block' : 'none' }}
        onMouseLeave={() =>
          setUserSettingsMenu({
            isOpened: false,
            backgroundIsOpened: false
          })
        }>
        {userSettingsMenuData.map((item) =>
          item.mainMenuItem === 'Background' ? (
            <li
              key={item.mainMenuItem}
              style={{
                backgroundColor: item.menuItemOpened && '#4792E2'
              }}
              className='settings-menu-item list'
              onClick={() => item.stateAssign(item.data)}>
              <div>
                <p>{item.mainMenuItem}</p>
                {item.menuItemOpened ? (
                  <MenuArrowDown
                    width={8}
                    fill='white'
                    className='margin-right-small-pointer settings-arrow'
                  />
                ) : (
                  <MenuArrowRight className='margin-right-small-pointer settings-arrow' />
                )}
              </div>
              {item.menuItemOpened && (
                <ul className='settings-menu settings-submenu'>
                  {item.submenu.map((subItem) => (
                    <li
                      key={subItem.submenuItem}
                      className='settings-menu-item'
                      style={{
                        backgroundColor: userSettings[subItem.type] === subItem.data && '#4792E2'
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        capturePosthogData({
                          event: 'update-settings',
                          settings_type: 'background',
                          type: 'user-settings',
                          change_from: userSettings[subItem.type],
                          change_to: subItem.data
                        });
                        userSettings[subItem.type] !== subItem.data &&
                          dispatch(
                            updateUserSettings({
                              userId,
                              settingsType: subItem.type,
                              changeFrom: userSettings[subItem.type],
                              changeTo: subItem.data
                            })
                          );
                      }}>
                      <p>{subItem.submenuItem}</p>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ) : (
            item.isShown && (
              <div
                key={item.mainMenuItem}
                className='settings-menu-item'
                style={{
                  width: 140,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                <p>{item.mainMenuItem}</p>
                <ToggleButton
                  offSymbol='Off'
                  onSymbol='On'
                  initialtogleOffState={item.initialData}
                  onToggleChange={item.updateData}
                />
              </div>
            )
          )
        )}
      </ul>
    </div>
  );
}

export default Zoom;
